(function () {
	'use strict';

	angular	
		.module('rockwills',  ['ngSanitize'])
		.controller('formCtrl', FormCtrl);

	angular
		.module('rockwills')
		.filter('yourfilter', function(){
	    return function(rawDate){
	       var dateString = '',
			dateMoment = moment(rawDate),
			date = dateMoment.toDate(),
			time = dateMoment.format('hh:mm a');
		
		// Make the user friendly date processor independent 
		// of the Moment js.
		// We can call this on the server side by setting the 
		// property of userFriendlyDate.

		var dateMilliSeconds = date.getTime(),
			secondsFromDate = ((new Date().getTime()) - dateMilliSeconds) / 1000; 
				
		if( secondsFromDate < 5 )
			return ' a second ago';
		
		if( secondsFromDate < 180 )
			return ' a minute ago';
		
		if( secondsFromDate < 3600 )
			return Math.floor( secondsFromDate / 60 ) + ' minutes ago';
		
		if( secondsFromDate < 7200 )
			return ' an hour ago';
		
		if( secondsFromDate < 86400 )
			return Math.floor( secondsFromDate / 3600 ) + ' hours ago';
		
		if( secondsFromDate < 172800 )
			return ' Yesterday at ' + time;
		
		return dateMoment.format('MMMM DD') + ' at ' + time;
	    }
	})

	angular
		.module('rockwills')
		.filter('trusted', ['$sce', function($sce){
		    var div = document.createElement('div');
		    return function(text) {
		        div.innerHTML = text;
		        return $sce.trustAsHtml(div.textContent);
		    };
		}])
	

	FormCtrl.$inject = ['$http', '$location', '$anchorScroll', '$document', '$scope', '$timeout'];
	
	function FormCtrl ($http, $location, $anchorScroll, $document, $scope, $timeout) {
		
		window.vm = this;

		vm.field_names = [
			{"index": "1", "title": "Will Details", "form_group": "details"},
	        {"index": "2", "title": "About the Testator", "form_group": "about"},
	        {"index": "3", "title": "Location of Assets & Attestation", "form_group": "location"},
	        {"index": "4", "title": "Executor & Trustee", "form_group": "executor"},
	        {"index": "5", "title": "Guardian of Minor Children", "form_group": "guardian"},
	        {"index": "6", "title": "Beneficiaries", "form_group": "beneficiaries"},
	        {"index": "7", "title": "Specific Gifts of Movable Assets", "form_group": "giftsmovable"},
	        {"index": "8", "title": "Specific Gifts of Immovable Assets", "form_group": "giftsimmovable"},
	        {"index": "9", "title": "Residuary Estate", "form_group": "residuary"},
	        {"index": "10", "title": "Other Matters", "form_group": "others"},
	        {"index": "11A", "title": "Testamentary Trust for Movable Assets", "form_group": "testamentarymovable"},
	        {"index": "11B", "title": "Testamentary Trust for Movable Assets", "form_group": "testamentarymovable_b"},
	        {"index": "11C", "title": "Testamentary Trust for Movable Assets", "form_group": "testamentarymovable_c"},
	        {"index": "12A", "title": "Testamentary Trust for Immovable Assets", "form_group": "testamentaryimmovable"},
	        {"index": "12B", "title": "Testamentary Trust for Immovable Assets", "form_group": "testamentaryimmovable_b"},
	        {"index": "12C", "title": "Testamentary Trust for Immovable Assets", "form_group": "testamentaryimmovable_c"},
	        {"index": "13", "title": "Digital Assets", "form_group": "digital_assets"},
	         {"index": "14", "title": "Submission of Instructions", "form_group": "submission"}
	       /* {"index": "14", "title": "File Upload", "form_group": "file_upload"}*/
	    ];

		vm.setSelected = setSelected;
		vm.setSelected2 = setSelected2;
		vm.setSelected3 = setSelected3;
	    vm.isSelected = isSelected;
	    vm.selected = null;
	    vm.selectedID = null;
	    vm.helper = null;
	    vm.showHelper = showHelper;
	    vm.submission_amount = 449.40;
	    vm.loadDisqus = loadDisqus;
	    vm.loadComments = loadComments;
	    vm.help_text = 'section_one';
	    vm.comments = null
	    vm.addNewExecutor = addNewExecutor;
	    vm.removeExecutor = removeExecutor;
	    vm.addNewDigitalFacilitator = addNewDigitalFacilitator;
	    vm.removeDigitalFacilitator = removeDigitalFacilitator;
	  
	    vm.addNewTestamentaryMovableTrustee = addNewTestamentaryMovableTrustee;
	    vm.removeTestamentaryMovableTrustee = removeTestamentaryMovableTrustee;
	    vm.addNewTestamentaryMovableTrusteeB = addNewTestamentaryMovableTrusteeB;
	    vm.removeTestamentaryMovableTrusteeB = removeTestamentaryMovableTrusteeB;
	    vm.addNewTestamentaryMovableTrusteeC = addNewTestamentaryMovableTrusteeC;
	    vm.removeTestamentaryMovableTrusteeC = removeTestamentaryMovableTrusteeC;

	    vm.addNewTestamentaryImmovableTrustee = addNewTestamentaryImmovableTrustee;
	    vm.removeTestamentaryImmovableTrustee = removeTestamentaryImmovableTrustee;
	    vm.addNewTestamentaryImmovableTrusteeB = addNewTestamentaryImmovableTrusteeB;
	    vm.removeTestamentaryImmovableTrusteeB = removeTestamentaryImmovableTrusteeB;
	    vm.addNewTestamentaryImmovableTrusteeC = addNewTestamentaryImmovableTrusteeC;
	    vm.removeTestamentaryImmovableTrusteeC = removeTestamentaryImmovableTrusteeC;
	    vm.removeModalExecutor = removeModalExecutor;
	    vm.removeModalDigitalFacilitator = removeModalDigitalFacilitator;
	    
	    vm.getIds = getIds;
	    vm.getUser = getUser;
	    vm.user = '';
	    vm.guardians = [{guardian_id:'1'}];
	    vm.beneficiaries = [{beneficiary_id: '1'}];
	    vm.asset_location = null;
	    vm.jointlyOwned = null;
	    vm.setJointlyOwned = setJointlyOwned;
	    vm.addChildren = addChildren;
	    vm.removeChildren = removeChildren;
	   	vm.children = [{children_id: '1'}]; 
	    vm.setAssetLocation = setAssetLocation;
	    vm.setDistribution = setDistribution;
	    vm.distribution = null;
	    vm.setPaymentExpenses = setPaymentExpenses;
	    vm.paymentExpense = null;
	    vm.language = null;
	    vm.setLanguage = setLanguage;
	    vm.special_condition = null;
	    vm.setSpecialCondition = setSpecialCondition;
	    // vm.theres_oral_translator = null;
	    vm.assets_theres_translator_options = null;

	    vm.testamentarymovable_theres_movableassets = null;
	    vm.b_testamentarymovable_theres_movableassets = null;
	    vm.c_testamentarymovable_theres_movableassets = null;

	    vm.testamentaryimmovable_theres_immovableassets = null;
	    vm.b_testamentaryimmovable_theres_immovableassets = null;
	    vm.c_testamentaryimmovable_theres_immovableassets = null;

	    vm.setOralTranslator = setOralTranslator;
	    
	    vm.setTestmentaryMovableAssets = setTestmentaryMovableAssets;
	    vm.setTestmentaryMovableAssets_b = setTestmentaryMovableAssets_b;
	    vm.setTestmentaryMovableAssets_c = setTestmentaryMovableAssets_c;

	    vm.setTestmentaryImmovableAssets = setTestmentaryImmovableAssets;
	    vm.setTestmentaryImmovableAssets_b = setTestmentaryImmovableAssets_b;
	    vm.setTestmentaryImmovableAssets_c = setTestmentaryImmovableAssets_c;


	    vm.executor_appoint_trustee = null;
	    vm.setAppointedTrustee = setAppointedTrustee;
	    vm.guardian_any = null;
	    vm.setGuardian = setGuardian;
	    vm.setGuardian2 = setGuardian2;
	    vm.movableasset_any = null;
	    vm.digitalasset_any = null;
	    vm.setMovableAssets = setMovableAssets;
	    vm.setDigitalAssets = setDigitalAssets;
	    vm.immovableasset_any = null;
	    vm.setImmovableAssets = setImmovableAssets;

	    vm.addNewGuardian = addNewGuardian;
	    vm.removeGuardian = removeGuardian;
	    vm.addNewBeneficiary = addNewBeneficiary;
	    
	    vm.removeBeneficiary = removeBeneficiary;
	    vm.movable_asset_type = null;
	    vm.digital_asset_type = null;
	    vm.setMovableAssetType = setMovableAssetType;
	    vm.setDigitalAssetType = setDigitalAssetType;
	    vm.movableassetJointlyForm = movableassetJointlyForm;
	    vm.digitalassetJointlyForm = digitalassetJointlyForm;
	    vm.movableassets = [{movableasset_id:'1'}];
	    vm.digitalassets = [{digitalasset_id:'1'}];
	    vm.addNewMovableAsset = addNewMovableAsset;
	    vm.addNewDigitalAsset = addNewDigitalAsset;
	    vm.removeMovableAsset = removeMovableAsset;
	    vm.removeDigitalAsset = removeDigitalAsset;
	    vm.ownership = null;
	    vm.setOwnership = setOwnership;
	    vm.mortgageSettlement = null;
	    vm.setMortgageSettlement = setMortgageSettlement;
	    vm.immovableAssets = [{immovableasset_id:'1'}];
	    vm.addNewImmovableAsset = addNewImmovableAsset;
	    vm.removeImmovableAsset = removeImmovableAsset;
	    vm.showOtherIdInput = showOtherIdInput;
	   	vm.beneficiaries_select = [{name: 'Select Beneficiary'}];
		
		vm.mabeneficiary_ig = [];
		vm.mabeneficiary_sg = [];
		vm.mabeneficiary_ssg = [];

		vm.dabeneficiary_ig = [];
		vm.dabeneficiary_sg = [];
		vm.dabeneficiary_ssg = [];

		vm.iabeneficiary_ig = [];
		vm.iabeneficiary_sg = [];
		vm.iabeneficiary_ssg = [];

		vm.residuary_ig = [];
		vm.residuary_sg = [];
		vm.residuary_ssg = [];

		vm.testamentarymovable_ig = [];
		vm.testamentarymovable_sg = [];
		vm.testamentarymovable_ssg = [];
		vm.testamentarymovable_trust_ig = [];
		vm.testamentarymovable_trust_sg = [];
		vm.testamentarymovable_trust_ssg = [];
		vm.b_testamentarymovable_ig = [];
		vm.b_testamentarymovable_sg = [];
		vm.b_testamentarymovable_ssg = [];
		vm.b_testamentarymovable_trust_ig = [];
		vm.b_testamentarymovable_trust_sg = [];
		vm.b_testamentarymovable_trust_ssg = [];
		vm.c_testamentarymovable_ig = [];
		vm.c_testamentarymovable_sg = [];
		vm.c_testamentarymovable_ssg = [];
		vm.c_testamentarymovable_trust_ig = [];
		vm.c_testamentarymovable_trust_sg = [];
		vm.c_testamentarymovable_trust_ssg = [];
		vm.testamentaryimmovable_ig = [];
		vm.testamentaryimmovable_sg = [];
		vm.testamentaryimmovable_ssg = [];
		vm.b_testamentaryimmovable_ig = [];
		vm.b_testamentaryimmovable_sg = [];
		vm.b_testamentaryimmovable_ssg = [];
		vm.c_testamentaryimmovable_ig = [];
		vm.c_testamentaryimmovable_sg = [];
		vm.c_testamentaryimmovable_ssg = [];

		
		vm.mabeneficiary_ig_index = 0;
		vm.mabeneficiary_sg_index = 0;
		vm.mabeneficiary_ssg_index = 0;

		vm.dabeneficiary_ig_index = 0;
		vm.dabeneficiary_sg_index = 0;
		vm.dabeneficiary_ssg_index = 0;

		vm.iabeneficiary_ig_index = 0;
		vm.iabeneficiary_sg_index = 0;
		vm.iabeneficiary_ssg_index = 0;
		vm.residuary_ig_index = 0;
		vm.residuary_sg_index = 0;
		vm.residuary_ssg_index = 0;
		vm.testamentarymovable_ig_index = 0;
		vm.testamentarymovable_sg_index = 0;
		vm.testamentarymovable_ssg_index = 0;
		vm.testamentarymovable_trust_ig_index = 0;
		vm.testamentarymovable_trust_sg_index = 0;
		vm.testamentarymovable_trust_ssg_index = 0;
		vm.b_testamentarymovable_ig_index = 0;
		vm.b_testamentarymovable_sg_index = 0;
		vm.b_testamentarymovable_ssg_index = 0;
		vm.b_testamentarymovable_trust_ig_index = 0;
		vm.b_testamentarymovable_trust_sg_index = 0;
		vm.b_testamentarymovable_trust_ssg_index = 0;
		vm.c_testamentarymovable_ig_index = 0;
		vm.c_testamentarymovable_sg_index = 0;
		vm.c_testamentarymovable_ssg_index = 0;
		vm.c_testamentarymovable_trust_ig_index = 0;
		vm.c_testamentarymovable_trust_sg_index = 0;
		vm.c_testamentarymovable_trust_ssg_index = 0;
		vm.testamentaryimmovable_ig_index = 0;
		vm.testamentaryimmovable_sg_index = 0;
		vm.testamentaryimmovable_ssg_index = 0;
		vm.b_testamentaryimmovable_ig_index = 0;
		vm.b_testamentaryimmovable_sg_index = 0;
		vm.b_testamentaryimmovable_ssg_index = 0;
		vm.c_testamentaryimmovable_ig_index = 0;
		vm.c_testamentaryimmovable_sg_index = 0;
		vm.c_testamentaryimmovable_ssg_index = 0;



		vm.addMabeneficiary_ig = addMabeneficiary_ig;
		vm.removeMabeneficiary_ig = removeMabeneficiary_ig;
	    vm.addMabeneficiary_sg = addMabeneficiary_sg;
		vm.removeMabeneficiary_sg = removeMabeneficiary_sg;

		// vm.addDabeneficiary_ig = addDabeneficiary_ig;
		// vm.removeDabeneficiary_ig = removeDabeneficiary_ig;
	 //    vm.addDabeneficiary_sg = addDabeneficiary_sg;
		// vm.removeDabeneficiary_sg = removeDabeneficiary_sg;

		// Immovable Assets
		vm.addIabeneficiary_ig = addIabeneficiary_ig;
		vm.removeIabeneficiary_ig = removeIabeneficiary_ig;
	    vm.addIabeneficiary_sg = addIabeneficiary_sg;
		vm.removeIabeneficiary_sg = removeIabeneficiary_sg;
		vm.addResiduary_ig = addResiduary_ig;
		vm.removeResiduary_ig = removeResiduary_ig;
		vm.addResiduary_sg = addResiduary_sg;
		vm.removeResiduary_sg = removeResiduary_sg;

	    // vm.executors = [];
	    vm.executors = [{'executor_id': 1}];

	    vm.digital_facilitators = [{'digital_facilitator_id': 1}];


	    vm.testamentarymovable_trustees = [{'testamentarymovable_trustee_id': 1}];
	    vm.b_testamentarymovable_trustees = [{'b_testamentarymovable_trustee_id': 1}];
	    vm.c_testamentarymovable_trustees = [{'c_testamentarymovable_trustee_id': 1}];
	    
	    vm.testamentaryimmovable_trustees = [{'testamentaryimmovable_trustee_id': 1}];
	    vm.b_testamentaryimmovable_trustees = [{'b_testamentaryimmovable_trustee_id': 1}];
	    vm.c_testamentaryimmovable_trustees = [{'c_testamentaryimmovable_trustee_id': 1}];


	    vm.beneficiary_name = '';
	    vm.retrieveInputs = retrieveInputs;
	    vm.retrieveSelect = retrieveSelect;
	    vm.jumpToLocation = jumpToLocation;

	    function retrieveSelect() {
	    	// console.log('asdf')
	    	$('#loading').addClass( "loading" );

	    	$http({
			    url: "/getWill/"+WILL_ID,
			    method: "GET",
				}).success(function(data, status, headers, config) {

					angular.forEach(data, function(value, key) {
				 		if(typeof(value) == 'object'){
	 						var objects = JSON.parse(angular.toJson(value));
				 			
				 			angular.forEach(objects, function(value1, key1) {

				 				if (key1.match(/movableasset_beneficiary_ig/i) || key1.match(/movableasset_beneficiary_sg/i) || key1.match(/movableasset_beneficiary_ssg/i) || key1.match(/digitalasset_beneficiary_ig/i) || key1.match(/digitalasset_beneficiary_sg/i) || key1.match(/digitalasset_beneficiary_ssg/i) || key1.match(/immovableasset_beneficiary_ig/i) || key1.match(/immovableasset_beneficiary_sg/i) || key1.match(/immovableasset_beneficiary_ssg/i) || key1.match(/residuary_ig/i) || key1.match(/residuary_sg/i) || key1.match(/residuary_ssg/i) || key1.match(/testamentarymovable_ig/i) || key1.match(/testamentarymovable_sg/i) || key1.match(/testamentarymovable_ssg/i) || key1.match(/testamentarymovable_trust_ig/i) || key1.match(/testamentarymovable_trust_sg/i) || key1.match(/testamentarymovable_trust_ssg/i) || key1.match(/b_testamentarymovable_ig/i) || key1.match(/b_testamentarymovable_sg/i) || key1.match(/b_testamentarymovable_ssg/i) || key1.match(/b_testamentarymovable_trust_ig/i) || key1.match(/b_testamentarymovable_trust_sg/i) || key1.match(/b_testamentarymovable_trust_ssg/i) || key1.match(/c_testamentarymovable_ig/i) || key1.match(/c_testamentarymovable_sg/i) || key1.match(/c_testamentarymovable_ssg/i) || key1.match(/c_testamentarymovable_trust_ig/i) || key1.match(/c_testamentarymovable_trust_sg/i) || key1.match(/c_testamentarymovable_trust_ssg/i) || key1.match(/testamentaryimmovable_ig/i) || key1.match(/testamentaryimmovable_sg/i) || key1.match(/testamentaryimmovable_ssg/i)  || key1.match(/b_testamentaryimmovable_ig/i) || key1.match(/b_testamentaryimmovable_sg/i) || key1.match(/b_testamentaryimmovable_ssg/i) || key1.match(/c_testamentaryimmovable_ig/i) || key1.match(/c_testamentaryimmovable_sg/i) || key1.match(/c_testamentaryimmovable_ssg/i) || key1.match(/testamentaryimmovable_beneficiaries/i) || key1.match(/b_testamentaryimmovable_beneficiaries/i) || key1.match(/c_testamentaryimmovable_beneficiaries/i)) {  
				 					// console.log(key1, value1)
									$("#"+key1).val(value1);
				 				}
				 			});
				 		}	
				 	});
				    vm.loadComments(vm.help_text) //load comments
				    vm.getUser(null)
				}).error(function(data, status, headers, config) {
			});

			$(".movableasset_asset_type").each(function(){
				// console.log($(this).val())
				if($(this).val() == '4') {
					// alert('show')
					$(this).closest('.form-group').next().show()
				}
				else {
					// alert('hide')
					$(this).closest('.form-group').next().hide()
				}	
			});

			$(".digitalasset_asset_type").each(function(){
				// console.log($(this).val())
				if($(this).val() == '4') {
					// alert('show')
					$(this).closest('.form-group').next().show()
				}
				else {
					// alert('hide')
					$(this).closest('.form-group').next().hide()
				}	
			});

	    	setTimeout(function(){ $('#loading').removeClass( "loading" ); }, 2000 )
	    }

	    function retrieveInputs () {
	    	$('#loading').addClass( "loading" );
    		$http({
			    url: "/getWill/"+WILL_ID,
			    method: "GET",
			}).success(function(data, status, headers, config) {

				$("#testamentarymovable_distribution_purpose_others").hide()
				$("#b_testamentarymovable_distribution_purpose_others").hide()
				$("#c_testamentarymovable_distribution_purpose_others").hide()

				angular.forEach(data, function(value, key) {
			 		
			 		if(typeof(value) != 'object'){
			 			if (key== 'type_id') {
			 				if(value == 1) {
			 					$("#testator_id_type1").prop('checked', true);
			 				}
			 				else if(value == 2) {
			 					$("#testator_id_type2").prop('checked', true);
			 				}else{
			 					$("#testator_id_type3").prop('checked', true);
			 				}
			 			};

			 			if(key == 'testator_sex') {

			 				if(value == 'male') {
			 					$("#radioTestatorSex1").prop('checked', true);
			 				}
			 				else {
			 					$("#radioTestatorSex2").prop('checked', true);
			 				}
			 			}
			 			else {

			 				if(key == 'type_id') {
			 					
			 					if (value == '3') {
			 						// console.log('sdjflasdkfjalsdkfjl')
			 						$("#other_id_type, #other_id_type_").show();
			 					}

			 					else {

			 						$("#other_id_type, #other_id_type_").hide();
			 					}
			 				}

			 				$("#"+key).val(value);
			 				// console.log(key, value)
			 			}
			 		}

			 		else {
			 			
			 			var objects = JSON.parse(angular.toJson(value));
			 			// e - executor
			 			// g - guardian
			 			// b - beneficiary
			 			// ia - immovable asset
			 			// mtit - testamentarymovable trustee
			 			//  tit - testamentaryimmovable trustee
			 			var e_index, df_index, g_index, b_index, ia_index, tit_index, titb_index,titc_index, mtit_index, mtitb_index,mtitc_index;

			 			angular.forEach(objects, function(value1, key1) {
			 				// console.log(key1)

			 				if(key1.match(/executor_index/i)){
			 					e_index = value1;
			 					//alert(e_index);
			 				}
			 				else if (key1.match(/digital_facilitator_index/i)){
			 					df_index = value1;
			 				}
			 				else if (key1.match(/guardian_index/i)){
			 					g_index = value1;
			 				}
			 				else if (key1.match(/beneficiary_index/i)){
			 					b_index = value1;
			 				}
			 				else if (key1.match(/immovableasset_index/i)) {
			 					ia_index = value1;
			 				}
			 				else if (key1.match(/^testamentarymovable_trustee_index/i)) {
			 					mtit_index = value1;
			 				}
			 				else if (key1.match(/^b_testamentarymovable_trustee_index/i)) {
			 					mtitb_index = value1;
			 				}
			 				else if (key1.match(/^c_testamentarymovable_trustee_index/i)) {
			 					mtitc_index = value1;
			 				}
			 				else if (key1.match(/^testamentaryimmovable_trustee_index/i)) {
			 					tit_index = value1;
			 				}
			 				else if (key1.match(/^b_testamentaryimmovable_trustee_index/i)) {
			 					titb_index = value1;
			 				}
			 				else if (key1.match(/^c_testamentaryimmovable_trustee_index/i)) {
			 					titc_index = value1;
			 				}
			 				else if (key1 == 'assets_location_options'){

			 					if(value1 == 'worldwide') {

			 						$("#assets_location_options1").prop('checked', true);
			 					}
			 					else if (value1 == 'sg') {

			 						$("#assets_location_options2").prop('checked', true);
			 					}
			 					else{
			 						$("#assets_location_options3").prop('checked', true);
			 						vm.asset_location = 'other';
			 					}
			 				}

			 				else if (key1 == 'assets_attestation_language_options') {
			 					if(value1 == 'english') {
			 						$("#assets_attestation_language_options1").prop('checked', true);
			 					}
			 					else if (value1 == 'thumbprint'){
			 						$("#assets_attestation_language_options2").prop('checked', true);
			 					}
			 					else {
			 						$("#assets_attestation_language_options3").prop('checked', true);
			 						vm.language = 'other';
			 					}
			 				}

			 				else if (key1 == 'assets_attestation_special_condition_options') {
			 					
			 					if (value1 == 'na') {
			 						$("#assets_attestation_special_condition_options4").prop('checked', true);
			 					}
			 					else if(value1 == 'noenglish') {
			 						$("#assets_attestation_special_condition_options1").prop('checked', true);
			 					}
			 					else if (value1 == 'visuallyimpaired' ) {
			 						$("#assets_attestation_special_condition_options2").prop('checked', true);
			 					}
			 					else {
			 						$("#assets_attestation_special_condition_options3").prop('checked', true);
			 						vm.special_condition = 'other';
			 					}
			 				}

			 				else if (key1 == 'executor_trustee_appointment') {
			 					// console.log(value1)
			 					if ( value1 == 'sole'){
			 						$("#executor_trustee_appointment1").prop('checked', true);
			 						vm.executor_appoint_trustee = 'sole';
			 					} else if (value1 == 'na') {
			 						$("#executor_trustee_appointment3").prop('checked', true);
			 						vm.executor_appoint_trustee = 'na';
			 					} else {
			 						$("#executor_trustee_appointment2").prop('checked', true);
			 						vm.executor_appoint_trustee = 'subsitute';
			 					}
			 				}


							else if ( key1 == 'executor_trustee_same'){

								if(value1 == 'yes') {
									$("#"+key1+"1").prop('checked', true);
								}
								else if(value1 == 'no') {
									$("#"+key1+"2").prop('checked', true);
								}

							}

			 				else if (key1 == 'guardian_any') {
			 					if ( value1 == 'yes'){
			 						$("#guardian_any_1").prop('checked', true);
			 						vm.guardian_any = 'yes';
			 					}  else {
			 						$("#guardian_any_2").prop('checked', true);
			 						vm.guardian_any = 'no';
			 					}
			 				}

			 				else if (key1 == 'executor_trustee_appointment_same_options') {
			 					
			 					if ( value1 == 'yes'){
			 						$("#executor_trustee_appointment_same_options1").prop('checked', true);
			 					}
			 					else {
			 						$("#executor_trustee_appointment_same_options2").prop('checked', true);
			 					}
			 				}

			 				else if ( key1.match(new RegExp('testamentarymovable_instructions_options|b_testamentarymovable_instructions_options\
				 				|c_testamentarymovable_instructions_options')) )  {
								if ( value1 == 'others') {
									$("#"+key1+"_others").show();
								}
								else {
									$("#"+key1+"_others").hide();
								}
								$("#"+key1).val(value1);
							}

			 				else if (key1 == 'testamentarymovable_schedule_options' || key1 == 'b_testamentarymovable_schedule_options' 
						         || key1 == 'c_testamentarymovable_schedule_options' || key1 == 'testamentarymovable_pay_schedule_options' 
						         || key1 == 'testamentarymovable_pay_schedule_options' || key1 == 'b_testamentarymovable_pay_schedule_options' 
						         || key1 == 'c_testamentarymovable_pay_schedule_options' ) {
			 					
			 					if (value1 == '1') {
			 						$("#"+key1+"1").prop('checked', true);
			 					}
			 					else if (value1 == '3') {
			 						$("#"+key1+"2").prop('checked', true);
			 					}
			 					else if (value1 == '6') {
			 						$("#"+key1+"3").prop('checked', true);
			 					}
			 					else if (value1 == '12') {
			 						$("#"+key1+"4").prop('checked', true);
			 					}
			 				}

			 				else if (key1 == 'testamentarymovable_convert_options' || key1 == 'b_testamentarymovable_convert_options' 
									|| key1 == 'c_testamentarymovable_convert_options') {
								if(value1 == 'Yes') {
									$("#"+key1+"1").prop('checked', true);
								}
								else if(value1 == 'No') {
									$("#"+key1+"2").prop('checked', true);
								}
							}

							else if (key1 == 'testamentarymovable_distribution_inflation_options' || key1 == 'b_testamentarymovable_distribution_inflation_options'
								|| key1 == 'c_testamentarymovable_distribution_inflation_options') {
									if(value1 == 'yes') {
										$("#"+key1+"1").prop('checked', true);
									}
									else if(value1 == 'no') {
										$("#"+key1+"2").prop('checked', true);
									}
							}

			 				else if ( key1.match(/testamentarymovable_trust_type_options|b_testamentarymovable_trust_type_options|c_testamentarymovable_trust_type_options/) ){
								if(value1 == 'joint') {
									$("#"+key1+"1").prop('checked', true);
								}
								else if(value1 == 'substitute') {
									$("#"+key1+"2").prop('checked', true);
								}
							}

			 				else if ( key1.match(/testamentarymovable_sex|b_testamentarymovable_sex|c_testamentarymovable_sex|testamentaryimmovable_sex/) ){
							 	if(value1 == 'male') {
							 		$("#"+key1+"1").prop('checked', true);
							 	}
							 	else if(value1 == 'female') {
							 		$("#"+key1+"2").prop('checked', true);
							 	}
							}

							else if ( key1.match(/testamentaryimmovable_beneficiaries_occupy|b_testamentaryimmovable_beneficiaries_occupy|c_testamentaryimmovable_beneficiaries_occupy/) ){
								if(value1 == 'yes') {
									$("#"+key1+"1").prop('checked', true);
								}
								else if(value1 == 'no') {
									$("#"+key1+"2").prop('checked', true);
								}
							}

							else if ( key1.match(/testamentaryimmovable_powertosell|b_testamentaryimmovable_powertosell|c_testamentaryimmovable_powertosell/) ){
								if(value1 == 'yes') {
									$("#"+key1+"1").prop('checked', true);
								}
								else if(value1 == 'no') {
									$("#"+key1+"2").prop('checked', true);
								}
							}

							else if ( key1.match(/testamentaryimmovable_payment_expenses_options|b_testamentaryimmovable_payment_expenses_options|c_testamentaryimmovable_payment_expenses_options/) ){
						 		if(value1 == 'others') {
									$("#"+key1+"_others").show();
								}
								else {
									$("#"+key1+"_others").hide();
								}
								$("#"+key1).val(value1);
							}

						 	else if ( key1.match(/testamentaryimmovable_trust_options|b_testamentaryimmovable_trust_options|c_testamentaryimmovable_trust_options/) ){
								if(value1 == 'transfer') {
									$("#"+key1+"1").prop('checked', true);
								}
								else if(value1 == 'liquidate') {
									$("#"+key1+"2").prop('checked', true);
								}
							}

				 			else if ( key1.match(/testamentaryimmovable_trust_type_options|b_testamentaryimmovable_trust_type_options|c_testamentaryimmovable_trust_type_options/) ){
								if(value1 == 'joint') {
									$("#"+key1+"1").prop('checked', true);
								}
								else if(value1 == 'substitute') {
									$("#"+key1+"2").prop('checked', true);
								}
							}

			 				else if (key1 == 'submission_fee_custody_options') {
			 					if (value1 == 'annual') {
			 						$("#submission_fee_custody_options1").prop('checked', true);
			 					}

			 					else if (value1 == 'lifetime') {
			 						$("#submission_fee_custody_options2").prop('checked', true);
			 					}

			 					else if (value1 == 'exclusive') {
			 						$("#submission_fee_custody_options3").prop('checked', true);
			 					}
			 				}

			 				else if (key1 == 'submission_fee_drafing_options') {
			 					$("#submission_fee_drafing_options"+value1).prop('checked', true);
			 				}

			 				else if (key1 == 'submission_fee_appointment') {
			 					if(value1=='yes') {
			 						$("#submission_fee_appointmentyes").prop('checked', true);	
			 					}
			 					else {
			 						$("#submission_fee_appointmentno").prop('checked', false);	
			 					}
			 				}

			 				else if (key1 == 'others_id_type') {
			 					if(value1 == 1) {
				 					$("#others_id_type1").prop('checked', true);
				 				}else if(value1 == 2) {
				 					$("#others_id_type2").prop('checked', true);
				 				}else{
				 					$("#others_id_type3").prop('checked', true);
				 					$(".others_other_id_type").show();
				 				}
			 				}

			 				else if (key1 == 'others_person_to_marry_id_type') {
			 					if(value1 == 1) {
				 					$("#others_person_to_marry_id_type1").prop('checked', true);
				 				}else if(value1 == 2) {
				 					$("#others_person_to_marry_id_type2").prop('checked', true);
				 				}else{
				 					$("#others_person_to_marry_id_type3").prop('checked', true);
				 					$(".others_other_person_to_marry_id_type").show();
				 				}
			 				}

			 				else if (key1 == 'testamentarymovable_id_type') {
								if(value1 == 1) {
				 					$("#testamentarymovable_id_type1").prop('checked', true);
				 				}else if(value1 == 2) {
				 					$("#testamentarymovable_id_type2").prop('checked', true);
				 				}else{
				 					$("#testamentarymovable_id_type3").prop('checked', true);
				 					$(".testamentarymovable_other_id_type").show();
				 				}			 					
			 				}

			 				else if (key1 == 'b_testamentarymovable_id_type') {
			 					if(value1 == 1) {
				 					$("#b_testamentarymovable_id_type1").prop('checked', true);
				 				}else if(value1 == 2) {
				 					$("#b_testamentarymovable_id_type2").prop('checked', true);
				 				}else{
				 					$("#b_testamentarymovable_id_type3").prop('checked', true);
				 					$(".b_testamentarymovable_other_id_type").show();
				 				}			 					
			 				}

			 				else if (key1 == 'c_testamentarymovable_id_type') {
			 					if(value1 == 1) {
				 					$("#c_testamentarymovable_id_type1").prop('checked', true);
				 				}else if(value1 == 2) {
				 					$("#c_testamentarymovable_id_type2").prop('checked', true);
				 				}else{
				 					$("#c_testamentarymovable_id_type3").prop('checked', true);
				 					$(".c_testamentarymovable_other_id_type").show();
				 				}			 					
			 				}

			 				else{
			 					if (key1 == 'assets_oral_transalator_id_type') {
		 							if(value1 == 1) {
					 					$("#assets_oral_transalator_id_type1").prop('checked', true);
					 					$('.assets_other_oral_transalator_id_type').hide();
					 				}
					 				else if(value1 == 2) {
					 					$("#assets_oral_transalator_id_type2").prop('checked', true);
					 					$('.assets_other_oral_transalator_id_type').hide();
					 				}else{
					 					$("#assets_oral_transalator_id_type3").prop('checked', true);
					 					$('.assets_other_oral_transalator_id_type').show();
					 				}
			 					}

			 					else if(key1.match(/executor_gender_options/i) || key1.match(/guardian_gender_options/i) || key1.match(/beneficiary_gender_options/i) || key1.match(/testamentaryimmovable_trustee_gender_options/i) || key1.match(/testamentarymovable_trustee_gender_options/i) ){
			 						if(value1 == 'male'){
			 							$("#"+key1+"_1").prop('checked', true);
			 						}
			 						else if (value1 == 'female')
			 						{
			 							$("#"+key1+"_2").prop('checked', true);
			 						}
			 					}

			 					else if (key1.match(/executor_every_options/i) || key1.match(/guardian_payment_schedule_options/i) || key1.match(/testamentaryimmovable_trustee_every_options/i) || key1.match(/testamentarymovable_trustee_every_options/i) ) {
			 						if(value1 == '1'){
			 							$("#"+key1+"_1").prop('checked', true);
			 						}

			 						else if (value1 == '3'){
			 							$("#"+key1+"_2").prop('checked', true);
			 						}

			 						else if (value1 == '6'){
			 							$("#"+key1+"_3").prop('checked', true);
			 						}

			 						else if (value1 == '12'){
			 							$("#"+key1+"_4").prop('checked', true);
			 						}
			 					}

			 					else if(key1.match(/digital_facilitator_gender_options/i) || key1.match(/guardian_gender_options/i) || key1.match(/beneficiary_gender_options/i) || key1.match(/testamentaryimmovable_trustee_gender_options/i) || key1.match(/testamentarymovable_trustee_gender_options/i) ){
			 						if(value1 == 'male'){
			 							$("#"+key1+"_1").prop('checked', true);
			 						}
			 						else if (value1 == 'female')
			 						{
			 							$("#"+key1+"_2").prop('checked', true);
			 						}
			 					}

			 					else if (key1.match(/digital_facilitator_every_options/i) || key1.match(/guardian_payment_schedule_options/i) || key1.match(/testamentaryimmovable_trustee_every_options/i) || key1.match(/testamentarymovable_trustee_every_options/i) ) {
			 						if(value1 == '1'){
			 							$("#"+key1+"_1").prop('checked', true);
			 						}

			 						else if (value1 == '3'){
			 							$("#"+key1+"_2").prop('checked', true);
			 						}

			 						else if (value1 == '6'){
			 							$("#"+key1+"_3").prop('checked', true);
			 						}

			 						else if (value1 == '12'){
			 							$("#"+key1+"_4").prop('checked', true);
			 						}
			 					}

			 					else if (key1.match(/testamentarymovable_distribution_purpose[0-9]/i) ) {
			 						// console.log('key1 ' + key1)
			 						// console.log('value1 ' + value1)
			 						if(value1 == '1'){
			 							$("#"+key1).prop('checked', true)

			 							if (key1=='testamentarymovable_distribution_purpose5') {
			 								$("#testamentarymovable_distribution_purpose_others").show()
			 							};
			 							if (key1=='b_testamentarymovable_distribution_purpose5') {
			 								$("#b_testamentarymovable_distribution_purpose_others").show()
			 							};
			 							if (key1=='c_testamentarymovable_distribution_purpose5') {
			 								$("#c_testamentarymovable_distribution_purpose_others").show()
			 							};
			 						}
			 					}

                                else if (key1.match(/executor_beneficiaries[0-9]/i) ) {
									$("#"+key1).prop('checked', true)
                                }

			 					else if (key1.match(/executor_trust_type_options|digital_facilitator_trust_type_options|guardian_trust_type_options|testamentaryimmovable_trustee_trust_type_options|testamentarymovable_trustee_trust_type_options/i)) {
			 						
			 						// console.log(key1)
			 						// console.log(value1)
			 						// console.log("#"+key1+"_2")

			 						if(value1 == 'joint'){
			 							$("#"+key1+"_1").prop('checked', true);
			 						}

			 						else if (value1 == 'substitute'){
			 							$("#"+key1+"_2").prop('checked', true);
			 						}
			 					}

			 					else if (key1.match(/guardian_type_options/i)) {
			 						if(value1 == 'surviving'){
			 							$("#"+key1+"_1").prop('checked', true);
			 						}

			 						else if (value1 == 'demise'){
			 							$("#"+key1+"_2").prop('checked', true);
			 						}
			 					}

			 					else if ( key1.match(/others_incude_children_options/i) || key1.match(/others_children_reference_options/i) || key1.match(/assets_theres_translator_options/i) || key1.match(/guardian_any/i) || key1.match(/movableasset_any/i) || key1.match(/digitalasset_any/i) ||  key1.match(/immovableasset_any/i) || key1.match(/movableasset_jointly_owned_options[0-9]/i) || key1.match(/digitalasset_jointly_owned_options[0-9]/i) || key1.match(/movableasset_jointly_owned_options/i) || key1.match(/digitalasset_jointly_owned_options/i) || key1.match(/testamentaryimmovable_theres_immovableassets/i) || key1.match(/testamentarymovable_theres_movableassets/i) ) {
			 						if(value1 == 'yes'){
			 							$("#"+key1+"_1").prop('checked', true);
			 							vm[key1] = 'yes';
			 							
			 						}
			 						else if (value1 == 'no'){
			 							$("#"+key1+"_2").prop('checked', true);
			 							vm[key1] = 'no';
			 							
			 						}
								}

			 					else if (key1.match(/immovableasset_action_options/i)) {
			 						if(value1 == 'transfer'){
			 							$("#"+key1+"_1").prop('checked', true);
			 						}
			 						else if (value1 == 'liquidate'){
			 							$("#"+key1+"_2").prop('checked', true);
			 						}
			 					}

			 					else if (key1.match(/executor_id_type/i)) {
		 							if(value1 == 1) {
					 					$("#executor_id_type_1_"+e_index).prop('checked', true);
					 				}else if(value1 == 2) {
					 					$("#executor_id_type_2_"+e_index).prop('checked', true);
					 				}else{
					 					$("#executor_id_type_3_"+e_index).prop('checked', true);
					 					$(".executor_other_id_type"+e_index).show();
					 				}
				 				}

				 				else if (key1.match(/digital_facilitator_id_type/i)) {
		 							if(value1 == 1) {
					 					$("#digital_facilitator_id_type_1_"+df_index).prop('checked', true);
					 				}else if(value1 == 2) {
					 					$("#digital_facilitator_id_type_2_"+df_index).prop('checked', true);
					 				}else{
					 					$("#digital_facilitator_id_type_3_"+df_index).prop('checked', true);
					 					$(".digital_facilitator_other_id_type"+df_index).show();
					 				}
				 				}

				 				else if (key1.match(/^testamentarymovable_trustee_id_type/i)) {
				 						// console.log('key A '+key1+' value '+value1+' tit_index '+mtit_index)
				 					$(".testamentarymovable_trustee_other_id_type"+mtit_index).hide();
		 							if(value1 == 1) {
					 					$("#testamentarymovable_trustee_id_type_1_"+mtit_index).prop('checked', true);
					 				}else if(value1 == 2) {
					 					$("#testamentarymovable_trustee_id_type_2_"+mtit_index).prop('checked', true);
					 				}else{
					 					$("#testamentarymovable_trustee_id_type_3_"+mtit_index).prop('checked', true);
					 					$(".testamentarymovable_trustee_other_id_type"+mtit_index).show();
					 				}
				 				}

				 				else if (key1.match(/^b_testamentarymovable_trustee_id_type/i)) {
				 					// console.log('key B '+key1+' value '+value1+' titb_index '+mtitb_index)
				 					$(".b_testamentarymovable_trustee_other_id_type"+mtitb_index).hide();
		 							if(value1 == 1) {
					 					$("#b_testamentarymovable_trustee_id_type_1_"+mtitb_index).prop('checked', true);
					 				}else if(	value1 == 2) {
					 					$("#b_testamentarymovable_trustee_id_type_2_"+mtitb_index).prop('checked', true);
					 				}else{
					 					$("#b_testamentarymovable_trustee_id_type_3_"+mtitb_index).prop('checked', true);
					 					$(".b_testamentarymovable_trustee_other_id_type"+mtitb_index).show();
					 				}
				 				}

				 				else if (key1.match(/^c_testamentarymovable_trustee_id_type/i)) {
				 					// console.log('key C '+key1+' value '+value1+' titc_index '+mtitc_index)
				 					$(".c_testamentarymovable_trustee_other_id_type"+mtitc_index).hide();
		 							if(value1 == 1) {
					 					$("#c_testamentarymovable_trustee_id_type_1_"+mtitc_index).prop('checked', true);
					 				}else if(value1 == 2) {
					 					$("#c_testamentarymovable_trustee_id_type_2_"+mtitc_index).prop('checked', true);
					 				}else{
					 					$("#c_testamentarymovable_trustee_id_type_3_"+mtitc_index).prop('checked', true);
					 					$(".c_testamentarymovable_trustee_other_id_type"+mtitc_index).show();
					 				}
				 				}


				 				else if (key1.match(/^testamentaryimmovable_trustee_id_type/i)) {
				 						// console.log('key A '+key1+' value '+value1+' tit_index '+tit_index)
				 						
				 					$(".testamentaryimmovable_trustee_other_id_type"+tit_index).hide();
		 							if(value1 == 1) {
					 					$("#testamentaryimmovable_trustee_id_type_1_"+tit_index).prop('checked', true);
					 				}else if(value1 == 2) {
					 					$("#testamentaryimmovable_trustee_id_type_2_"+tit_index).prop('checked', true);
					 				}else{
					 					$("#testamentaryimmovable_trustee_id_type_3_"+tit_index).prop('checked', true);
					 					$(".testamentaryimmovable_trustee_other_id_type"+tit_index).show();
					 				}
				 				}

				 				else if (key1.match(/^b_testamentaryimmovable_trustee_id_type/i)) {
				 					// console.log('key B '+key1+' value '+value1+' titb_index '+titb_index)
				 					$(".b_testamentaryimmovable_trustee_other_id_type"+titb_index).hide();
		 							if(value1 == 1) {
					 					$("#b_testamentaryimmovable_trustee_id_type_1_"+titb_index).prop('checked', true);
					 				}else if(	value1 == 2) {
					 					$("#b_testamentaryimmovable_trustee_id_type_2_"+titb_index).prop('checked', true);
					 				}else{
					 					$("#b_testamentaryimmovable_trustee_id_type_3_"+titb_index).prop('checked', true);
					 					$(".b_testamentaryimmovable_trustee_other_id_type"+titb_index).show();
					 				}
				 				}

				 				else if (key1.match(/^c_testamentaryimmovable_trustee_id_type/i)) {
				 					// console.log('key C '+key1+' value '+value1+' titc_index '+titc_index)
				 					$(".c_testamentaryimmovable_trustee_other_id_type"+titc_index).hide();
		 							if(value1 == 1) {
					 					$("#c_testamentaryimmovable_trustee_id_type_1_"+titc_index).prop('checked', true);
					 				}else if(value1 == 2) {
					 					$("#c_testamentaryimmovable_trustee_id_type_2_"+titc_index).prop('checked', true);
					 				}else{
					 					$("#c_testamentaryimmovable_trustee_id_type_3_"+titc_index).prop('checked', true);
					 					$(".c_testamentaryimmovable_trustee_other_id_type"+titc_index).show();
					 				}
				 				}



			 					else if (key1.match(/guardian_id_type/i)) {
			 						$(".guardian_other_id_type"+g_index).hide();
			 						if(value1 == 1) {
					 					$("#guardian_id_type_1_"+g_index).prop('checked', true);
					 				}else if(value1 == 2) {
					 					$("#guardian_id_type_2_"+g_index).prop('checked', true);
					 				}else{
					 					$("#guardian_id_type_3_"+g_index).prop('checked', true);
					 					$(".guardian_other_id_type"+g_index).show();
					 				}
			 					}

			 					else if (key1.match(/beneficiary_id_type/i)) {
			 						// console.log(key1, b_index, value1)
			 						$(".beneficiary_other_id_type"+b_index).hide();
			 						if(value1 == 1) {
					 					$("#beneficiary_id_type_1_"+b_index).prop('checked', true);
					 				}else if(value1 == 2) {
					 					$("#beneficiary_id_type_2_"+b_index).prop('checked', true);
					 				}else{
					 					$("#beneficiary_id_type_3_"+b_index).prop('checked', true);
					 					$(".beneficiary_other_id_type"+b_index).show();
					 				}
			 					}

			 					else if (key1.match(/immovableasset_ownership_options/i)) {
			 							
			 						$('#'+key1).on('change', function (){
			 							
			 							var coowners_index = key1.substr(32, 2)
			 							if($('#'+key1+" option:selected").text() == 'Joint Tenancy'){

									    	$("#immovableasset_coowners_form"+coowners_index).show();

									    }
									    else{
									    	
									    	$("#immovableasset_coowners_form"+coowners_index).hide();
									    }
			 						});

			 						if( value1 == '1'){

			 							$("#immovableasset_coowners_form"+ia_index).show();
			 						}
			 						else {
			 							
			 							$("#immovableasset_coowners_form"+ia_index).hide();
			 						}
			 					}

			 					else if (key1.match(/immovableasset_mortgage_remains_options/i)) {
			 						$('#'+key1).on('change', function (){
			 							
			 							if($('#'+key1+" option:selected").text() == 'Other means'){
									    	$("#immovableasset_other_mortgage_remains"+ia_index).show();
									    }
									    
									    else{
									    	$("#immovableasset_other_mortgage_remains"+ia_index).hide();
									    }
			 						});

			 						if( value1 == '2'){
			 							
			 							$("#immovableasset_other_mortgage_remains"+ia_index).show();
			 						}

			 						else {
			 							
			 							$("#immovableasset_other_mortgage_remains"+ia_index).hide();
			 						}
			 					}

			 					else if (key1.match(/movableasset_beneficiary_ig/i)) {
			 					}

			 					$("#"+key1).val(value1);
			 				}
			 			});
			 		}
				});
			    
			}).error(function(data, status, headers, config) {
			    
			});
	    }

		function showHelper (e) {
			//vm.helper = e;
			//console.log("here")
		}

		function loadDisqus (e,x) {
			// x = window.location.href+'/'+x;
			// reset(e,x,e,'en');	
		}

		function loadComments (e) {
			
			vm.help_text = e
			
			$.ajax({
                type: 'POST',
                url: '../../comments',
                data: {help_text: vm.help_text},
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                success: function (data) {
                	// console.log(data)
                	$timeout(function() {
				        vm.comments = data
				    });
                	// console.log(vm.comments)
                },
                error: function (data) {
                   // console.log(data);
                }
            });
		
		}
		function getUser () {
			$.ajax({
                type: 'POST',
                url: '../../getuser',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                success: function (data) {
                	$timeout(function() {
				      vm.user = data[0].pivot.role_id
				      // console.log(data[0].pivot.role_id)
				    });
                	// console.log(vm.user)
                },
                error: function (data) {
                   // console.log(data);
                }
            });
		}

		$scope.deleteComment = function (id) { // developer is not passed in...
			// console.log(id)
			swal({
              title: "Are you sure?",
              // text: "Your will not be able to recover this imaginary file!",
              type: "info",
              showCancelButton: true,
              confirmButtonClass: "btn-success",
              confirmButtonText: "Yes, delete it!",
              closeOnConfirm: false
            },
            function(){
               $.ajax({
                    type: 'POST',
                    url: '../../commentdelete',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: {id:id},
                    success: function(response){
                        // console.log(response)
                        if (response==1) {
                            swal("Comment Deleted!")
                            vm.loadComments(vm.help_text)
                        };

                    }
                });
            });
		
		}
		//Add the error marker
		vm.addErrorMarker = function (e) {

			if(angular.isUndefined(e))
			{
				return 0;
			}
			return 1;
		}
		
		function getIds () {

			var executors = JSON.parse($("#executors").val());
			var digital_facilitators = JSON.parse($("#digital_facilitators").val());
			var guardians = JSON.parse($("#guardians").val());
			var beneficiaries = JSON.parse($("#beneficiaries").val());
			var movable_assets = JSON.parse($("#movable_assets").val());
			// var digital_assets = JSON.parse($("#digital_assets").val());
			var immovable_assets = JSON.parse($("#immovable_assets").val());
			var ma_beneficiary_ig_index = JSON.parse($("#ma_beneficiary_ig").val());
			var ma_beneficiary_sg_index = JSON.parse($("#ma_beneficiary_sg").val());
			var ma_beneficiary_ssg_index = JSON.parse($("#ma_beneficiary_ssg").val());

			var da_beneficiary_ig_index = JSON.parse($("#da_beneficiary_ig").val());
			var da_beneficiary_sg_index = JSON.parse($("#da_beneficiary_sg").val());
			var da_beneficiary_ssg_index = JSON.parse($("#da_beneficiary_ssg").val());

			var ia_beneficiary_ig_index = JSON.parse($("#ia_beneficiary_ig").val());
			var ia_beneficiary_sg_index = JSON.parse($("#ia_beneficiary_sg").val());
			var ia_beneficiary_ssg_index = JSON.parse($("#ia_beneficiary_ssg").val());

			var residuary_ig_index = JSON.parse($("#residuary_ig").val());
			var residuary_sg_index = JSON.parse($("#residuary_sg").val());
			var residuary_ssg_index = JSON.parse($("#residuary_ssg").val());

			var testamentarymovable_ig_index = JSON.parse($("#testamentarymovable_ig").val());
			var testamentarymovable_sg_index = JSON.parse($("#testamentarymovable_sg").val());
			var testamentarymovable_ssg_index = JSON.parse($("#testamentarymovable_ssg").val());

			var testamentarymovable_trust_ig_index = JSON.parse($("#testamentarymovable_trust_ig").val());
			var testamentarymovable_trust_sg_index = JSON.parse($("#testamentarymovable_trust_sg").val());
			var testamentarymovable_trust_ssg_index = JSON.parse($("#testamentarymovable_trust_ssg").val());

			var testamentarymovable_ig_index_b = JSON.parse($("#b_testamentarymovable_ig").val());
			var testamentarymovable_sg_index_b = JSON.parse($("#b_testamentarymovable_sg").val());
			var testamentarymovable_ssg_index_b = JSON.parse($("#b_testamentarymovable_ssg").val());

			var testamentarymovable_trust_ig_index_b = JSON.parse($("#b_testamentarymovable_trust_ig").val());
			var testamentarymovable_trust_sg_index_b = JSON.parse($("#b_testamentarymovable_trust_sg").val());
			var testamentarymovable_trust_ssg_index_b = JSON.parse($("#b_testamentarymovable_trust_ssg").val());

			var testamentarymovable_ig_index_c = JSON.parse($("#c_testamentarymovable_ig").val());
			var testamentarymovable_sg_index_c = JSON.parse($("#c_testamentarymovable_sg").val());
			var testamentarymovable_ssg_index_c = JSON.parse($("#c_testamentarymovable_ssg").val());

			var testamentarymovable_trust_ig_index_c = JSON.parse($("#c_testamentarymovable_trust_ig").val());
			var testamentarymovable_trust_sg_index_c = JSON.parse($("#c_testamentarymovable_trust_sg").val());
			var testamentarymovable_trust_ssg_index_c = JSON.parse($("#c_testamentarymovable_trust_ssg").val());

			var testamentaryimmovable_trustees = JSON.parse($("#testamentaryimmovable_trustees").val());
			var b_testamentaryimmovable_trustees = JSON.parse($("#b_testamentaryimmovable_trustees").val());
			var c_testamentaryimmovable_trustees = JSON.parse($("#c_testamentaryimmovable_trustees").val());

			var testamentarymovable_trustees = JSON.parse($("#testamentarymovable_trustees").val());
			var b_testamentarymovable_trustees = JSON.parse($("#b_testamentarymovable_trustees").val());
			var c_testamentarymovable_trustees = JSON.parse($("#c_testamentarymovable_trustees").val());

			var testamentaryimmovable_ig_index = JSON.parse($("#testamentaryimmovable_ig").val());
			var testamentaryimmovable_sg_index = JSON.parse($("#testamentaryimmovable_sg").val());
			var testamentaryimmovable_ssg_index = JSON.parse($("#testamentaryimmovable_ssg").val());

			var testamentaryimmovable_ig_index_b = JSON.parse($("#b_testamentaryimmovable_ig").val());
			var testamentaryimmovable_sg_index_b = JSON.parse($("#b_testamentaryimmovable_sg").val());
			var testamentaryimmovable_ssg_index_b = JSON.parse($("#b_testamentaryimmovable_ssg").val());


			var testamentaryimmovable_ig_index_c = JSON.parse($("#c_testamentaryimmovable_ig").val());
			var testamentaryimmovable_sg_index_c = JSON.parse($("#c_testamentaryimmovable_sg").val());
			var testamentaryimmovable_ssg_index_c = JSON.parse($("#c_testamentaryimmovable_ssg").val());

			var others_matter_children_index = JSON.parse($("#others_matter_children").val());

			angular.forEach(executors, function(value, key) {
				
				if(value != 1){
				 	vm.executors.push({
		    			'executor_id': value,
		    		});
			 	}
			});

			angular.forEach(digital_facilitators, function(value, key) {
				
				if(value != 1){
				 	vm.digital_facilitators.push({
		    			'digital_facilitator_id': value,
		    		});
			 	}
			});

			angular.forEach(testamentarymovable_trustees, function(value, key) {
				if(value != 1){
				 	vm.testamentarymovable_trustees.push({
		    			'testamentarymovable_trustee_id': value,
		    		});
			 	}
			});

			angular.forEach(b_testamentarymovable_trustees, function(value, key) {
				if(value != 1){
				 	vm.b_testamentarymovable_trustees.push({
		    			'b_testamentarymovable_trustee_id': value,
		    		});
			 	}
			});

			angular.forEach(c_testamentarymovable_trustees, function(value, key) {
				if(value != 1){
				 	vm.c_testamentarymovable_trustees.push({
		    			'c_testamentarymovable_trustee_id': value,
		    		});
			 	}
			});


			angular.forEach(testamentaryimmovable_trustees, function(value, key) {
				if(value != 1){
				 	vm.testamentaryimmovable_trustees.push({
		    			'testamentaryimmovable_trustee_id': value,
		    		});
			 	}
			});

			angular.forEach(b_testamentaryimmovable_trustees, function(value, key) {
				if(value != 1){
				 	vm.b_testamentaryimmovable_trustees.push({
		    			'b_testamentaryimmovable_trustee_id': value,
		    		});
			 	}
			});

			angular.forEach(c_testamentaryimmovable_trustees, function(value, key) {
				if(value != 1){
				 	vm.c_testamentaryimmovable_trustees.push({
		    			'c_testamentaryimmovable_trustee_id': value,
		    		});
			 	}
			});

			angular.forEach(guardians, function(value, key) {
				
				if(value != 1){
				 	vm.guardians.push({
		    			'guardian_id': value,
		    		});
			 	}
			});

			angular.forEach(beneficiaries, function(value, key) {
				// console.log(key)
				// console.log(value)
				if(value != 1){
				 	vm.beneficiaries.push({
		    			'beneficiary_id': value,
		    		});
			 	}
			});

			angular.forEach(movable_assets, function(value, key) {
				
				if(value != 1){
				 	vm.movableassets.push({
		    			'movableasset_id': value,
		    		});
			 	}
			});

			// angular.forEach(digital_assets, function(value, key) {
				
			// 	if(value != 1){
			// 	 	vm.digitalassets.push({
		 //    			'digitalasset_id': value,
		 //    		});
			//  	}
			// });

			angular.forEach(immovable_assets, function(value, key) {
				
				if(value != 1){
				 	vm.immovableAssets.push({
		    			'immovableasset_id': value,
		    		});
			 	}
			});

			angular.forEach(others_matter_children_index, function(value, key) {
				
				if(value != 1){
				 	vm.children.push({
		    			'children_id': value,
		    		});
			 	}
			});

			//build form
			angular.element(document).ready(function () {
       			// console.log('wew')
       			angular.forEach(ma_beneficiary_ig_index, function(value, key) {	
					vm.addMabeneficiary_ig(value,'movableasset_beneficiary_ig_id', 'movableasset_beneficiary_ig','movableasset_beneficiary_ig_share','movableasset_beneficiary_ig_code', 1, 'movableasset_beneficiary_ig_remarks', '', 'movableasset_beneficiary_ig_index');
				});

				angular.forEach(ma_beneficiary_sg_index, function(value, key) {	
					vm.addMabeneficiary_ig(value,'movableasset_beneficiary_sg_id', 'movableasset_beneficiary_sg','movableasset_beneficiary_sg_share','movableasset_beneficiary_sg_code',2, 'movableasset_beneficiary_sg_remarks', '', 'movableasset_beneficiary_sg_index');
				});

				angular.forEach(ma_beneficiary_ssg_index, function(value, key) {	
					vm.addMabeneficiary_ig(value,'movableasset_beneficiary_ssg_id', 'movableasset_beneficiary_ssg','movableasset_beneficiary_ssg_share','movableasset_beneficiary_ssg_code',2.1, 'movableasset_beneficiary_ssg_remarks', '', 'movableasset_beneficiary_ssg_index');
				});

				angular.forEach(da_beneficiary_ig_index, function(value, key) {	
					vm.addMabeneficiary_ig(value,'digitalasset_beneficiary_ig_id', 'digitalasset_beneficiary_ig','digitalasset_beneficiary_ig_share','digitalasset_beneficiary_ig_code', 13, 'digitalasset_beneficiary_ig_remarks', '', 'digitalasset_beneficiary_ig_index');
				});

				angular.forEach(da_beneficiary_sg_index, function(value, key) {	
					vm.addMabeneficiary_ig(value,'digitalasset_beneficiary_sg_id', 'digitalasset_beneficiary_sg','digitalasset_beneficiary_sg_share','digitalasset_beneficiary_sg_code',13.1, 'digitalasset_beneficiary_sg_remarks', '', 'digitalasset_beneficiary_sg_index');
				});

				angular.forEach(da_beneficiary_ssg_index, function(value, key) {	
					vm.addMabeneficiary_ig(value,'digitalasset_beneficiary_ssg_id', 'digitalasset_beneficiary_ssg','digitalasset_beneficiary_ssg_share','digitalasset_beneficiary_ssg_code',13.2, 'digitalasset_beneficiary_ssg_remarks', '', 'digitalasset_beneficiary_ssg_index');
				});

				angular.forEach(ia_beneficiary_ig_index, function(value, key) {
					// console.log(value)
					// console.log(key)
					vm.addMabeneficiary_ig(value,'immovableasset_beneficiary_ig_id', 'immovableasset_beneficiary_ig','immovableasset_beneficiary_ig_share','immovableasset_beneficiary_ig_code',3, 'immovableasset_beneficiary_ig_remarks','','immovableasset_beneficiary_ig_index');
				});

				angular.forEach(ia_beneficiary_sg_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'immovableasset_beneficiary_sg_id', 'immovableasset_beneficiary_sg','immovableasset_beneficiary_sg_share','immovableasset_beneficiary_sg_code',4, 'immovableasset_beneficiary_sg_remarks','','immovableasset_beneficiary_sg_index');
				});

				angular.forEach(ia_beneficiary_ssg_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'immovableasset_beneficiary_ssg_id', 'immovableasset_beneficiary_ssg','immovableasset_beneficiary_ssg_share','immovableasset_beneficiary_ssg_code',4.1, 'immovableasset_beneficiary_ssg_remarks','','immovableasset_beneficiary_ssg_index');
				});

				angular.forEach(residuary_ig_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'residuary_ig_id', 'residuary_ig','residuary_ig_share','residuary_ig_code',5, 'residuary_ig_remarks','','residuary_ig_index');
				});

				angular.forEach(residuary_sg_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'residuary_sg_id', 'residuary_sg','residuary_sg_share','residuary_sg_code',6, 'residuary_sg_remarks','','residuary_sg_index');
				});

				angular.forEach(residuary_ssg_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'residuary_ssg_id', 'residuary_ssg','residuary_ssg_share','residuary_ssg_code',6.1, 'residuary_ssg_remarks','','residuary_ssg_index');
				});

				angular.forEach(testamentarymovable_ig_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'testamentarymovable_ig_id', 'testamentarymovable_ig','testamentarymovable_ig_share','testamentarymovable_ig_code',7, 'testamentarymovable_ig_remarks','','testamentarymovable_ig_index');
				});

				angular.forEach(testamentarymovable_sg_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'testamentarymovable_sg_id', 'testamentarymovable_sg','testamentarymovable_sg_share','testamentarymovable_sg_code',8, 'testamentarymovable_sg_remarks','','testamentarymovable_sg_index');
				});

				angular.forEach(testamentarymovable_ssg_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'testamentarymovable_ssg_id', 'testamentarymovable_ssg','testamentarymovable_ssg_share','testamentarymovable_ssg_code',81, 'testamentarymovable_ssg_remarks','','testamentarymovable_ssg_index');
				});

				angular.forEach(testamentarymovable_ig_index_b, function(value, key) {
					vm.addMabeneficiary_ig(value,'b_testamentarymovable_ig_id', 'b_testamentarymovable_ig','b_testamentarymovable_ig_share','b_testamentarymovable_ig_code',7.1, 'b_testamentarymovable_ig_remarks','','b_testamentarymovable_ig_index');
				});

				angular.forEach(testamentarymovable_sg_index_b, function(value, key) {
					vm.addMabeneficiary_ig(value,'b_testamentarymovable_sg_id', 'b_testamentarymovable_sg','b_testamentarymovable_sg_share','b_testamentarymovable_sg_code',8.1, 'b_testamentarymovable_sg_remarks','','b_testamentarymovable_sg_index');
				});

				angular.forEach(testamentarymovable_ssg_index_b, function(value, key) {
					vm.addMabeneficiary_ig(value,'b_testamentarymovable_ssg_id', 'b_testamentarymovable_ssg','b_testamentarymovable_ssg_share','b_testamentarymovable_ssg_code',81.1, 'b_testamentarymovable_ssg_remarks','','b_testamentarymovable_ssg_index');
				});

				angular.forEach(testamentarymovable_ig_index_c, function(value, key) {
					vm.addMabeneficiary_ig(value,'c_testamentarymovable_ig_id', 'c_testamentarymovable_ig','c_testamentarymovable_ig_share','c_testamentarymovable_ig_code',7.2, 'c_testamentarymovable_ig_remarks','','c_testamentarymovable_ig_index');
				});

				angular.forEach(testamentarymovable_sg_index_c, function(value, key) {
					vm.addMabeneficiary_ig(value,'c_testamentarymovable_sg_id', 'c_testamentarymovable_sg','c_testamentarymovable_sg_share','c_testamentarymovable_sg_code',8.2, 'c_testamentarymovable_sg_remarks','','c_testamentarymovable_sg_index');
				});

				angular.forEach(testamentarymovable_ssg_index_c, function(value, key) {
					vm.addMabeneficiary_ig(value,'c_testamentarymovable_ssg_id', 'c_testamentarymovable_ssg','c_testamentarymovable_ssg_share','c_testamentarymovable_ssg_code',81.2, 'c_testamentarymovable_ssg_remarks','','c_testamentarymovable_ssg_index');
				});

				angular.forEach(testamentarymovable_trust_ig_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'testamentarymovable_trust_ig_id', 'testamentarymovable_trust_ig','testamentarymovable_trust_ig_share','testamentarymovable_trust_ig_code',9, 'testamentarymovable_trust_ig_remarks','','testamentarymovable_trust_ig_index');
				});

				angular.forEach(testamentarymovable_trust_sg_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'testamentarymovable_trust_sg_id', 'testamentarymovable_trust_sg','testamentarymovable_trust_sg_share','testamentarymovable_trust_sg_code',10, 'testamentarymovable_trust_sg_remarks','','testamentarymovable_trust_sg_index');
				});

				angular.forEach(testamentarymovable_trust_ssg_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'testamentarymovable_trust_ssg_id', 'testamentarymovable_trust_ssg','testamentarymovable_trust_ssg_share','testamentarymovable_trust_ssg_code',100, 'testamentarymovable_trust_ssg_remarks','','testamentarymovable_trust_ssg_index');
				});

				angular.forEach(testamentarymovable_trust_ig_index_b, function(value, key) {
					vm.addMabeneficiary_ig(value,'b_testamentarymovable_trust_ig_id', 'b_testamentarymovable_trust_ig','b_testamentarymovable_trust_ig_share','b_testamentarymovable_trust_ig_code',9.1, 'b_testamentarymovable_trust_ig_remarks','','b_testamentarymovable_trust_ig_index');
				});

				angular.forEach(testamentarymovable_trust_sg_index_b, function(value, key) {
					vm.addMabeneficiary_ig(value,'b_testamentarymovable_trust_sg_id', 'b_testamentarymovable_trust_sg','b_testamentarymovable_trust_sg_share','b_testamentarymovable_trust_sg_code',10.1, 'b_testamentarymovable_trust_sg_remarks','','b_testamentarymovable_trust_sg_index');
				});

				angular.forEach(testamentarymovable_trust_ssg_index_b, function(value, key) {
					vm.addMabeneficiary_ig(value,'b_testamentarymovable_trust_ssg_id', 'b_testamentarymovable_trust_ssg','b_testamentarymovable_trust_ssg_share','b_testamentarymovable_trust_ssg_code',100.1, 'b_testamentarymovable_trust_ssg_remarks','','b_testamentarymovable_trust_ssg_index');
				});

				angular.forEach(testamentarymovable_trust_ig_index_c, function(value, key) {
					vm.addMabeneficiary_ig(value,'c_testamentarymovable_trust_ig_id', 'c_testamentarymovable_trust_ig','c_testamentarymovable_trust_ig_share','c_testamentarymovable_trust_ig_code',9.2, 'c_testamentarymovable_trust_ig_remarks','','c_testamentarymovable_trust_ig_index');
				});

				angular.forEach(testamentarymovable_trust_sg_index_c, function(value, key) {
					vm.addMabeneficiary_ig(value,'c_testamentarymovable_trust_sg_id', 'c_testamentarymovable_trust_sg','c_testamentarymovable_trust_sg_share','c_testamentarymovable_trust_sg_code',10.2, 'c_testamentarymovable_trust_sg_remarks','','c_testamentarymovable_trust_sg_index');
				});

				angular.forEach(testamentarymovable_trust_ssg_index_c, function(value, key) {
					vm.addMabeneficiary_ig(value,'c_testamentarymovable_trust_ssg_id', 'c_testamentarymovable_trust_ssg','c_testamentarymovable_trust_ssg_share','c_testamentarymovable_trust_ssg_code',100.2, 'c_testamentarymovable_trust_ssg_remarks','','c_testamentarymovable_trust_ssg_index');
				});

				angular.forEach(testamentaryimmovable_ig_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'testamentaryimmovable_ig_id', 'testamentaryimmovable_ig','testamentaryimmovable_ig_share','testamentaryimmovable_ig_code',11, 'testamentaryimmovable_ig_remarks','','testamentaryimmovable_ig_index');
				});

				angular.forEach(testamentaryimmovable_sg_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'testamentaryimmovable_sg_id', 'testamentaryimmovable_sg','testamentaryimmovable_sg_share','testamentaryimmovable_sg_code',12, 'testamentaryimmovable_sg_remarks','','testamentaryimmovable_sg_index');
				});

				angular.forEach(testamentaryimmovable_ssg_index, function(value, key) {
					vm.addMabeneficiary_ig(value,'testamentaryimmovable_ssg_id', 'testamentaryimmovable_ssg','testamentaryimmovable_ssg_share','testamentaryimmovable_ssg_code',120, 'testamentaryimmovable_ssg_remarks','','testamentaryimmovable_ssg_index');
				});

				angular.forEach(testamentaryimmovable_ig_index_b, function(value, key) {
					vm.addMabeneficiary_ig(value,'b_testamentaryimmovable_ig_id', 'b_testamentaryimmovable_ig','b_testamentaryimmovable_ig_share','b_testamentaryimmovable_ig_code',11.1, 'b_testamentaryimmovable_ig_remarks','','b_testamentaryimmovable_ig_index');
				});

				angular.forEach(testamentaryimmovable_sg_index_b, function(value, key) {
					vm.addMabeneficiary_ig(value,'b_testamentaryimmovable_sg_id', 'b_testamentaryimmovable_sg','b_testamentaryimmovable_sg_share','b_testamentaryimmovable_sg_code',12.1, 'b_testamentaryimmovable_sg_remarks','','b_testamentaryimmovable_sg_index');
				});

				angular.forEach(testamentaryimmovable_ssg_index_b, function(value, key) {
					vm.addMabeneficiary_ig(value,'b_testamentaryimmovable_ssg_id', 'b_testamentaryimmovable_ssg','b_testamentaryimmovable_ssg_share','b_testamentaryimmovable_ssg_code',120.1, 'b_testamentaryimmovable_ssg_remarks','','b_testamentaryimmovable_ssg_index');
				});

				angular.forEach(testamentaryimmovable_ig_index_c, function(value, key) {
					vm.addMabeneficiary_ig(value,'c_testamentaryimmovable_ig_id', 'c_testamentaryimmovable_ig','c_testamentaryimmovable_ig_share','c_testamentaryimmovable_ig_code',11.2, 'c_testamentaryimmovable_ig_remarks','','c_testamentaryimmovable_ig_index');
				});

				angular.forEach(testamentaryimmovable_sg_index_c, function(value, key) {
					vm.addMabeneficiary_ig(value,'c_testamentaryimmovable_sg_id', 'c_testamentaryimmovable_sg','c_testamentaryimmovable_sg_share','c_testamentaryimmovable_sg_code',12.2, 'c_testamentaryimmovable_sg_remarks','','c_testamentaryimmovable_sg_index');
				});

				angular.forEach(testamentaryimmovable_ssg_index_c, function(value, key) {
					vm.addMabeneficiary_ig(value,'c_testamentaryimmovable_ssg_id', 'c_testamentaryimmovable_ssg','c_testamentaryimmovable_ssg_share','c_testamentaryimmovable_ssg_code',120.2, 'c_testamentaryimmovable_ssg_remarks','','c_testamentaryimmovable_ssg_index');
				});
    		});
		}

		vm.getIds();
		vm.showOtherIdInput();
		vm.retrieveInputs();
	    
	    //Set the current selected
	    function setSelected (selected){

    		$('#loading').addClass( "loading" );
	    	// console.log('setSelected')

	    	$('.submit_button').trigger('click')

	    	// console.log(selected.index)

	    	if (selected.index==1) {
	    		// console.log( 'loading ' + 1 )
	    		vm.loadComments('section_one')
	    	}else if (selected.index==2) {
	    		// console.log( 'loading ' + 2 )
	    		vm.loadComments('section_two')
	    	}else if (selected.index==3) {
	    		// console.log( 'loading ' + 3 )
	    		vm.loadComments('section_three')
	    	}else if (selected.index==4) {
	    		// console.log( 'loading ' + 4 )
	    		vm.loadComments('section_four')
	    	}else if (selected.index==5) {
	    		// console.log( 'loading ' + 5 )
	    		vm.loadComments('section_five')
	    	}else if (selected.index==6) {
	    		// console.log( 'loading ' + 6 )
	    		vm.loadComments('section_six')
	    	}else if (selected.index==7) {
	    		// console.log( 'loading ' + 7 )
	    		vm.loadComments('section_seven')
	    	}else if (selected.index==8) {
	    		// console.log( 'loading ' + 8 )
	    		vm.loadComments('section_eight')
	    	}else if (selected.index==9) {
	    		// console.log( 'loading ' + 9 )
	    		vm.loadComments('section_nine')
	    	}else if (selected.index==10) {
	    		// console.log( 'loading ' + 10 )
	    		vm.loadComments('section_ten')
	    	}else if (selected.index=='11A') {
	    		// console.log( 'loading ' + '11a' )
	    		vm.loadComments('section_eleven')
	    	}else if (selected.index=='11B') {
	    		// console.log( 'loading ' + '11b' )
	    		vm.loadComments('section_eleven')
	    	}else if (selected.index=='11C') {
	    		// console.log( 'loading ' + '11C' )
	    		vm.loadComments('section_eleven')
	    	}else if (selected.index=='12A') {
	    		// console.log( 'loading ' + '12a' )
	    		vm.loadComments('section_twelve')
	    	}else if (selected.index=='12B') {
	    		// console.log( 'loading ' + '12B' )
	    		vm.loadComments('section_twelve')
	    	}else if (selected.index=='12C') {
	    		// console.log( 'loading ' + '12c' )
	    		vm.loadComments('section_twelve')
	    	}else {
	    		// console.log( 'loading ' + 13 )
	    		vm.loadComments('section_submission')
	    	};


			vm.selected = selected;

	    }

	     function setSelected3 (){

				vm.beneficiaries_select = [];
				angular.element(document).ready(function () {
					$(".beneficiary_select option").remove();
					
					$("[id^='guardian_name'], [id^='executor_name'], [id^='beneficiary_name']").each(function(){
						if($(this).val() != '') {
							var b = $("<option>"+$(this).val()+"</option>")
							.attr('value',$(this).val());
							$(".beneficiary_select").append(b);
							// $('#testamentaryimmovable_name').
						}
					});
					
					$timeout( function(){ 
			    		$('.beneficiary_code').each(function(){
			    			// console.log($(this).val(), ctr_val)
			    			var other_subs = $(this).closest('.form-group').next()
			    			if ($(this).val()=='OTP') {
			    				other_subs.show()
			    			}
			    		})
		    		}, 1000)

					vm.retrieveSelect();	
				});
			// }

			// if(selected.form_group == 'executor'){
			// 	addGuardianBeneficiary()
			// }

	    }


	  	//INITIALIZE Set the current selected
	    function setSelected2 (selected){	
    	
	    	// console.log('setSelected2')
	    	$('#loading').addClass( "loading" );
	    	// $('.submit_button').trigger('click')
		
			vm.selected = selected;
			// console.log($scope.selected.editor_name);
			// console.log(selected.form_group)
				vm.beneficiaries_select = [];
				angular.element(document).ready(function () {
					$(".beneficiary_select option").remove();
					
					$("[id^='guardian_name'], [id^='executor_name'], [id^='beneficiary_name']").each(function(){
						if($(this).val() != '') {
							var b = $("<option>"+$(this).val()+"</option>")
							.attr('value',$(this).val());
							$(".beneficiary_select").append(b);
							// $('#testamentaryimmovable_name').
						}
					});
			})

			setTimeout(function(){ $('#loading').removeClass( "loading" ); }, 3000 )

	    }


	    function addGuardianBeneficiary(){
	    		var ctr = 0;
	    		$(".executor_select").empty();
				$("[id^='guardian_name'], [id^='beneficiary_name']").each(function(){
					
					if($(this).val() != '') {
						var b = $("<div class='checkbox'>" +
				            "<label>" +
				                "<input id='"+'executor_beneficiaries'+ctr+"' type='checkbox' value='" +$(this).val() +"' name='"+'executor_beneficiaries'+ctr+"'>" +
				                $(this).val() +
				            "</label>" +
    						"</div>")
						$(".executor_select").append(b);
					}
					ctr++
				});

				vm.retrieveInputs();
	    }

	    function isSelected (selected){
			return vm.selected !== null && selected.index === vm.selected.index;
	    }

	    function jumpToLocation (key) {
	    	
    		$timeout( function(){ 
    			$('html, body').animate({
            		scrollTop: $("#"+key).offset().top - 60
            	}, 500); 

    			//focusthe el
    			$("#"+key).focus();

            }, 100);	
	    }

	    function finder(cmp, arr, attr) {
		    var val = arr[0][attr];
		    for(var i=1;i<arr.length;i++) {
		        val = cmp(val, arr[i][attr])
		    }
		    return val;
		}

	    function addNewExecutor () {
	    	
	    	$('.submit_button').trigger('click')
	    	console.log(vm.executors)
			var t = vm.executors
			var  index = finder(Math.max, t, "executor_id") + 1 ;

	    	// var index = vm.executors.length+1;

	    	vm.executors.push({
	    		'executor_id': index,
	    	});

	    	vm.executors_last_index = index

	    	$timeout( function(){ 
    			$(".executor_other_id_type"+index).hide();
    			addGuardianBeneficiary()
				load_radio_id_type()
    		},1000);
   			
   			setTimeout(function(){ $('#loading').removeClass( "loading" ); }, 4000 )

	    }

	    function addNewDigitalFacilitator () {
	    	
	    	$('.submit_button').trigger('click')

	    	console.log(vm.digital_facilitators)
			var t = vm.digital_facilitators

			var  index = finder(Math.max, t, "digital_facilitator_id") + 1 ;

	    	vm.digital_facilitators.push({
	    		'digital_facilitator_id': index,
	    	});

	    	vm.digital_facilitators_last_index = index

	    	$timeout( function(){ 
    			$(".digital_facilitator_other_id_type"+index).hide();
    			// addGuardianBeneficiary()
				load_radio_id_type()
    		},1000);
   			
   			setTimeout(function(){ $('#loading').removeClass( "loading" ); }, 4000 )

	    }
	    
	    function addNewTestamentaryMovableTrustee () {
	    	
	    	var index = vm.testamentarymovable_trustees.length+1;
	    	vm.testamentarymovable_trustees.push({
	    		'testamentarymovable_trustee_id': index,
	    	});

	    	$timeout( function(){ 
    			$(".testamentarymovable_trustee_other_id_type"+index).hide();
				load_radio_id_type()
    		},0);
   		
	    }

	    function addNewTestamentaryMovableTrusteeB () {
	    	
	    	var index = vm.b_testamentarymovable_trustees.length+1;
	    	vm.b_testamentarymovable_trustees.push({
	    		'b_testamentarymovable_trustee_id': index,
	    	});

	    	$timeout( function(){ 
    			$(".b_testamentarymovable_trustee_other_id_type"+index).hide();
				load_radio_id_type()
    		},0);
   		
	    }


	    function addNewTestamentaryMovableTrusteeC () {
	    	
	    	var index = vm.c_testamentarymovable_trustees.length+1;
	    	vm.c_testamentarymovable_trustees.push({
	    		'c_testamentarymovable_trustee_id': index,
	    	});

	    	$timeout( function(){ 
    			$(".c_testamentarymovable_trustee_other_id_type"+index).hide();
				load_radio_id_type()
    		},0);
   		
	    }


	    function addNewTestamentaryImmovableTrustee () {
	    	
	    	var index = vm.testamentaryimmovable_trustees.length+1;
	    	vm.testamentaryimmovable_trustees.push({
	    		'testamentaryimmovable_trustee_id': index,
	    	});

	    	$timeout( function(){ 
    			$(".testamentaryimmovable_trustee_other_id_type"+index).hide();
				load_radio_id_type()
    		},0);
   		
	    }

	     function addNewTestamentaryImmovableTrusteeB () {
	    	
	    	var index = vm.b_testamentaryimmovable_trustees.length+1;
	    	vm.b_testamentaryimmovable_trustees.push({
	    		'b_testamentaryimmovable_trustee_id': index,
	    	});

	    	$timeout( function(){ 
    			$(".b_testamentaryimmovable_trustee_other_id_type"+index).hide();
				load_radio_id_type()
    		},0);
   		
	    }

	    function addNewTestamentaryImmovableTrusteeC () {
	    	
	    	var index = vm.c_testamentaryimmovable_trustees.length+1;
	    	vm.c_testamentaryimmovable_trustees.push({
	    		'c_testamentaryimmovable_trustee_id': index,
	    	});

	    	$timeout( function(){ 
    			$(".c_testamentaryimmovable_trustee_other_id_type"+index).hide();
				load_radio_id_type()
    		},0);
   		
	    }

	    function removeExecutor (e) {

	    	if(!confirm('Are you sure you want to remove this executor?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.executors.indexOf(e);
	    	vm.executors.splice(item,1);
	    }

	    function removeDigitalFacilitator (e) {

	    	if(!confirm('Are you sure you want to remove this Facilitator?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.digital_facilitators.indexOf(e);
	    	vm.digital_facilitators.splice(item,1);
	    }

	    function removeTestamentaryMovableTrustee (e) {

	    	if(!confirm('Are you sure you want to remove this Testamentary Movable Trustee?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.testamentarymovable_trustees.indexOf(e);
	    	vm.testamentarymovable_trustees.splice(item,1);
	    }

	    function removeTestamentaryMovableTrusteeB (e) {

	    	if(!confirm('Are you sure you want to remove this Testamentary Movable Trustee?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.b_testamentarymovable_trustees.indexOf(e);
	    	vm.b_testamentarymovable_trustees.splice(item,1);
	    }

	    function removeTestamentaryMovableTrusteeC (e) {

	    	if(!confirm('Are you sure you want to remove this Testamentary Movable Trustee?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.c_testamentarymovable_trustees.indexOf(e);
	    	vm.c_testamentarymovable_trustees.splice(item,1);
	    }


	    function removeTestamentaryImmovableTrustee (e) {

	    	if(!confirm('Are you sure you want to remove this Testamentary Immovable Trustee?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.testamentaryimmovable_trustees.indexOf(e);
	    	vm.testamentaryimmovable_trustees.splice(item,1);
	    }

	     function removeTestamentaryImmovableTrusteeB (e) {

	    	if(!confirm('Are you sure you want to remove this Testamentary Immovable Trustee?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.b_testamentaryimmovable_trustees.indexOf(e);
	    	vm.b_testamentaryimmovable_trustees.splice(item,1);
	    }

	      function removeTestamentaryImmovableTrusteeC (e) {

	    	if(!confirm('Are you sure you want to remove this Testamentary Immovable Trustee?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.c_testamentaryimmovable_trustees.indexOf(e);
	    	vm.c_testamentaryimmovable_trustees.splice(item,1);
	    }

	    function addChildren () {

	    	var index = vm.children.length+1;
	    	vm.children.push({
	    		'children_id': index,
	    	});
	    }

	    function removeChildren(e) {
	    	if(!confirm('Are you sure you want to remove this child?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.children.indexOf(e);
    		vm.children.splice(item,1);
	    }

	    function removeModalExecutor (id) {
	    	$("#removeExecutorModal"+id).modal('show');
	    }

	    function removeModalDigitalFacilitator (id) {
	    	$("#removeDigitalFacilitatorModal"+id).modal('show');
	    }

	    function addNewGuardian () {
	    	var i = vm.guardians.length;
	    	var index = vm.guardians.length+1;
	    	vm.guardians.push({
	    		'guardian_id':index
	    	});
	    	$timeout( function(){ 
	    		$(".guardian_other_id_type"+index).hide();
				load_radio_id_type()
    		},0);
	    }

	    function removeGuardian (e) {
	    	if(!confirm('Are you sure you want to remove this guardian?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.guardians.indexOf(e);
	    	vm.guardians.splice(item,1);
	    }

	    function addNewBeneficiary () {
	    	
	    	function finder(cmp, arr, attr) {
			    var val = arr[0][attr];
			    for(var i=1;i<arr.length;i++) {
			        val = cmp(val, arr[i][attr])
			    }
			    return val;
			}

			var t = vm.beneficiaries
			var  index = finder(Math.max, t, "beneficiary_id") + 1 ;
	    	
	    	vm.beneficiaries_last_index = index
			
	  	   	var name = $("#beneficiary_name"+index).val();

	    	vm.beneficiaries.push({
	    		'beneficiary_id': index
	    	});

	    	load_beneficiaries()
		}

		function getMaxValue(d){
		    if(typeof d === "number") {
		        return d;
		    } else if(typeof d === "object") {
		        return _.max(_.map(_.keys(d), function(key) {
		            return getMaxValue(d[key]);
		        }));
		    } else {
		        return false;
		    }
		}

		function beneficiary_preload(){
			$timeout( function(){ 

	    		$('.beneficiary_type').each(function(){
	    			// console.log('this val' + $(this).val())
	    			if($(this).val() == '0') {
    					$(this).closest('.ng-scope').find('.beneficiary_gender, .beneficiary_relationship, .beneficiary_idtype').show()
    					// console.log('person')
    				}
    				else {
    					$(this).closest('.ng-scope').find('.beneficiary_gender, .beneficiary_relationship, .beneficiary_idtype').hide()

    					$(this).closest('.ng-scope').find('.beneficiary_gender_options1').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_gender_options2').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_id_type1').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_id_type2').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_id_type3').prop("checked", true);
    					$(this).closest('.ng-scope').find('.beneficiary_id_type4').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_relationship_input').val('')
    					$(this).closest('.ng-scope').find('.beneficiary_other_id_type').hide()
    					$(this).closest('.ng-scope').find('.beneficiary_other_id_input').show()
    					// $(this).closest('.ng-scope').find('.beneficiary_other_id_input').show()
    					// console.log('charity company')
    				}
	    		})
    		}, 5000)
		}

	    
	    /**
	    * x - index id
	    * y - select
	    * z - share percentage
	    * r - remarks
	    * ctr_val - index
	    * ci - container index
	    */
	    function addMabeneficiary_ig (ctr_val, x, y ,z, w, a, r, ci1, ci2) {
	    	// console.log('hey '+a)
	    	var index;
	    	var container;
	    	// console.log(w)
	    	// console.log(a)
	    	if (a == 1){
	    		// vm.mabeneficiary_ig.push({
	    		// 	'mabeneficiary_ig_id':vm.mabeneficiary_ig.length+1
	    		// });
				if (ctr_val=='') { //if blank index++
					index = parseInt(vm.mabeneficiary_ig_index) + 1
					vm.mabeneficiary_ig_index++
					container = 'mabeneficiary_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_'); //split the ctr_val to get the container index and beneficiary index 
					container = 'mabeneficiary_ig_container'+value[0]; //div id container index
					vm.mabeneficiary_ig_index = index = value[1] //beneficiary index
					var ci1 = value[0] //assign the container index to ci1
				}
	    		// index = vm.mabeneficiary_ig.length+1;
	    		$('#mabeneficiary_ig_container_rm').show()
	    	}	
	    	else if (a == 2) {
				if (ctr_val=='') { //if blank index++
					index = parseInt(vm.mabeneficiary_sg_index) + 1
					vm.mabeneficiary_sg_index++
					container = 'mabeneficiary_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'mabeneficiary_sg_container'+value[0]; //div id container
					vm.mabeneficiary_sg_index = index = value[1]
				}
				$('#mabeneficiary_sg_container_rm').show()
	    	}
	    	else if (a == 2.1) {
				if (ctr_val=='') { //if blank index++
					index = parseInt(vm.mabeneficiary_ssg_index) + 1
					vm.mabeneficiary_ssg_index++
					container = 'mabeneficiary_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'mabeneficiary_ssg_container'+value[0]; //div id container
					vm.mabeneficiary_ssg_index = index = value[1]
				}
				$('#mabeneficiary_ssg_container_rm').show()
	    	}
	    	else if (a == 13){
	    		// vm.mabeneficiary_ig.push({
	    		// 	'mabeneficiary_ig_id':vm.mabeneficiary_ig.length+1
	    		// });
				if (ctr_val=='') { //if blank index++
					index = parseInt(vm.dabeneficiary_ig_index) + 1
					vm.dabeneficiary_ig_index++
					container = 'dabeneficiary_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_'); //split the ctr_val to get the container index and beneficiary index 
					container = 'dabeneficiary_ig_container'+value[0]; //div id container index
					vm.dabeneficiary_ig_index = index = value[1] //beneficiary index
					var ci1 = value[0] //assign the container index to ci1
				}
	    		// index = vm.dabeneficiary_ig.length+1;
	    		$('#dabeneficiary_ig_container_rm').show()
	    	}	
	    	else if (a == 13.1) {
				if (ctr_val=='') { //if blank index++
					index = parseInt(vm.dabeneficiary_sg_index) + 1
					vm.dabeneficiary_sg_index++
					container = 'dabeneficiary_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'dabeneficiary_sg_container'+value[0]; //div id container
					vm.dabeneficiary_sg_index = index = value[1]
				}
				$('#dabeneficiary_sg_container_rm').show()
	    	}
	    	else if (a == 13.2) {
				if (ctr_val=='') { //if blank index++
					index = parseInt(vm.dabeneficiary_ssg_index) + 1
					vm.dabeneficiary_ssg_index++
					container = 'dabeneficiary_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'dabeneficiary_ssg_container'+value[0]; //div id container
					vm.dabeneficiary_ssg_index = index = value[1]
				}
				$('#dabeneficiary_ssg_container_rm').show()
	    	}
	    	else if (a == 3) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.iabeneficiary_ig_index) + 1
					vm.iabeneficiary_ig_index++
					container = 'iabeneficiary_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					// console.log(value)
					container = 'iabeneficiary_ig_container'+value[0]; //div id container
					vm.iabeneficiary_ig_index = index = value[1]
				}
				$('#iabeneficiary_ig_container_rm').show()
	    	}
	    	else if (a == 4) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.iabeneficiary_sg_index) + 1
					vm.iabeneficiary_sg_index++
					container = 'iabeneficiary_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'iabeneficiary_sg_container'+value[0]; //div id container
					vm.iabeneficiary_sg_index = index = value[1]
				}
				$('#iabeneficiary_sg_container_rm').show()
	    	}
	    	else if (a == 4.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.iabeneficiary_ssg_index) + 1
					vm.iabeneficiary_ssg_index++
					container = 'iabeneficiary_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'iabeneficiary_ssg_container'+value[0]; //div id container
					vm.iabeneficiary_ssg_index = index = value[1]
				}
				$('#iabeneficiary_ssg_index_rm').show()
	    	}
	    	else if (a == 5) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.residuary_ig_index) + 1
					vm.residuary_ig_index++
					container = 'residuary_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'residuary_ig_container'+value[0]; //div id container
					vm.residuary_ig_index = index = value[1]
				}
				$('#residuary_ig_container_rm').show()
	    	}
	    	else if (a == 6) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.residuary_sg_index) + 1
					vm.residuary_sg_index++
					container = 'residuary_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'residuary_sg_container'+value[0]; //div id container
					vm.residuary_sg_index = index = value[1]
				}
				$('#residuary_sg_container_rm').show()
	    	}
	    	else if (a == 6.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.residuary_ssg_index) + 1
					vm.residuary_ssg_index++
					container = 'residuary_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'residuary_ssg_container'+value[0]; //div id container
					vm.residuary_ssg_index = index = value[1]
				}
				$('#residuary_ssg_container_rm').show()
	    	}
	    	else if (a == 7) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.testamentarymovable_ig_index) + 1
					vm.testamentarymovable_ig_index++
					container = 'testamentarymovable_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'testamentarymovable_ig_container'+value[0]; //div id container
					vm.testamentarymovable_ig_index = index = value[1]
				}
				$('#testamentarymovable_ig_container_rm').show()
	    	}
	    	else if (a == 7.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.b_testamentarymovable_ig_index) + 1
					vm.b_testamentarymovable_ig_index++
					container = 'b_testamentarymovable_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'b_testamentarymovable_ig_container'+value[0]; //div id container
					vm.b_testamentarymovable_ig_index = index = value[1]
				}
				$('#b_testamentarymovable_ig_container_rm').show()
	    	}
	    	else if (a == 7.2) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.c_testamentarymovable_ig_index) + 1
					vm.c_testamentarymovable_ig_index++
					container = 'c_testamentarymovable_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'c_testamentarymovable_ig_container'+value[0]; //div id container
					vm.c_testamentarymovable_ig_index = index = value[1]
				}
				$('#c_testamentarymovable_ig_container_rm').show()
	    	}
	    	else if (a == 8) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.testamentarymovable_sg_index) + 1
					vm.testamentarymovable_sg_index++
					container = 'testamentarymovable_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'testamentarymovable_sg_container'+value[0]; //div id container
					vm.testamentarymovable_sg_index = index = value[1]
				}
				$('#testamentarymovable_sg_container_rm').show()
	    	}
	    	else if (a == 81) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.testamentarymovable_ssg_index) + 1
					vm.testamentarymovable_ssg_index++
					container = 'testamentarymovable_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'testamentarymovable_ssg_container'+value[0]; //div id container
					vm.testamentarymovable_ssg_index = index = value[1]
				}
				$('#testamentarymovable_ssg_container_rm').show()
	    	}
	    	else if (a == 8.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.b_testamentarymovable_sg_index) + 1
					vm.b_testamentarymovable_sg_index++
					container = 'b_testamentarymovable_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'b_testamentarymovable_sg_container'+value[0]; //div id container
					vm.b_testamentarymovable_sg_index = index = value[1]
				}
				$('#b_testamentarymovable_sg_container_rm').show()
	    	}
	    	else if (a == 81.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.b_testamentarymovable_ssg_index) + 1
					vm.b_testamentarymovable_ssg_index++
					container = 'b_testamentarymovable_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'b_testamentarymovable_ssg_container'+value[0]; //div id container
					vm.b_testamentarymovable_ssg_index = index = value[1]
				}
				$('#b_testamentarymovable_ssg_container_rm').show()
	    	}
	    	else if (a == 8.2) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.c_testamentarymovable_sg_index) + 1
					vm.c_testamentarymovable_sg_index++
					container = 'c_testamentarymovable_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'c_testamentarymovable_sg_container'+value[0]; //div id container
					vm.c_testamentarymovable_sg_index = index = value[1]
				}
				$('#c_testamentarymovable_sg_container_rm').show()
	    	}

	    	else if (a == 81.2) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.c_testamentarymovable_ssg_index) + 1
					vm.c_testamentarymovable_ssg_index++
					container = 'c_testamentarymovable_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'c_testamentarymovable_ssg_container'+value[0]; //div id container
					vm.c_testamentarymovable_ssg_index = index = value[1]
				}
				$('#c_testamentarymovable_ssg_container_rm').show()
	    	}

	    	else if (a == 9) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.testamentarymovable_trust_ig_index) + 1
					vm.testamentarymovable_trust_ig_index++
					container = 'testamentarymovable_trust_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'testamentarymovable_trust_ig_container'+value[0]; //div id container
					vm.testamentarymovable_trust_ig_index = index = value[1]
				}
				$('#testamentarymovable_trust_ig_container_rm').show()
	    	}
	    	else if (a == 9.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.b_testamentarymovable_trust_ig_index) + 1
					vm.b_testamentarymovable_trust_ig_index++
					container = 'b_testamentarymovable_trust_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'b_testamentarymovable_trust_ig_container'+value[0]; //div id container
					vm.b_testamentarymovable_trust_ig_index = index = value[1]
				}
				$('#b_testamentarymovable_trust_ig_container_rm').show()
	    	}
	    	else if (a == 9.2) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.c_testamentarymovable_trust_ig_index) + 1
					vm.c_testamentarymovable_trust_ig_index++
					container = 'c_testamentarymovable_trust_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'c_testamentarymovable_trust_ig_container'+value[0]; //div id container
					vm.c_testamentarymovable_trust_ig_index = index = value[1]
				}
				$('#c_testamentarymovable_trust_ig_container_rm').show()
	    	}
	    	else if (a == 10) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.testamentarymovable_trust_sg_index) + 1
					vm.testamentarymovable_trust_sg_index++
					container = 'testamentarymovable_trust_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'testamentarymovable_trust_sg_container'+value[0]; //div id container
					vm.testamentarymovable_trust_sg_index = index = value[1]
				}
				$('#testamentarymovable_trust_sg_container_rm').show()
	    	}
	    	else if (a == 100) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.testamentarymovable_trust_ssg_index) + 1
					vm.testamentarymovable_trust_ssg_index++
					container = 'testamentarymovable_trust_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'testamentarymovable_trust_ssg_container'+value[0]; //div id container
					vm.testamentarymovable_trust_ssg_index = index = value[1]
				}
				$('#testamentarymovable_trust_ssg_container_rm').show()
	    	}
	    	else if (a == 10.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.b_testamentarymovable_trust_sg_index) + 1
					vm.b_testamentarymovable_trust_sg_index++
					container = 'b_testamentarymovable_trust_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'b_testamentarymovable_trust_sg_container'+value[0]; //div id container
					vm.b_testamentarymovable_trust_sg_index = index = value[1]
				}
				$('#b_testamentarymovable_trust_sg_container_rm').show()
	    	}
	    	else if (a == 100.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.b_testamentarymovable_trust_ssg_index) + 1
					vm.b_testamentarymovable_trust_ssg_index++
					container = 'b_testamentarymovable_trust_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'b_testamentarymovable_trust_ssg_container'+value[0]; //div id container
					vm.b_testamentarymovable_trust_ssg_index = index = value[1]
				}
				$('#b_testamentarymovable_trust_ssg_container_rm').show()
	    	}
	    	else if (a == 10.2) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.c_testamentarymovable_trust_sg_index) + 1
					vm.c_testamentarymovable_trust_sg_index++
					container = 'c_testamentarymovable_trust_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'c_testamentarymovable_trust_sg_container'+value[0]; //div id container
					vm.c_testamentarymovable_trust_sg_index = index = value[1]
				}
				$('#c_testamentarymovable_trust_sg_container_rm').show()
	    	}
	    	else if (a == 100.2) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.c_testamentarymovable_trust_ssg_index) + 1
					vm.c_testamentarymovable_trust_ssg_index++
					container = 'c_testamentarymovable_trust_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'c_testamentarymovable_trust_ssg_container'+value[0]; //div id container
					vm.c_testamentarymovable_trust_ssg_index = index = value[1]
				}
				$('#c_testamentarymovable_trust_ssg_container_rm').show()
	    	}
	    	else if (a == 11) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.testamentaryimmovable_ig_index) + 1
					vm.testamentaryimmovable_ig_index++
					container = 'testamentaryimmovable_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'testamentaryimmovable_ig_container'+value[0]; //div id container
					vm.testamentaryimmovable_ig_index = index = value[1]
				}
				$('#testamentaryimmovable_ig_container_rm').show()
	    	}
	    	else if (a == 11.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.b_testamentaryimmovable_ig_index) + 1
					vm.b_testamentaryimmovable_ig_index++
					container = 'b_testamentaryimmovable_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'b_testamentaryimmovable_ig_container'+value[0]; //div id container
					vm.b_testamentaryimmovable_ig_index = index = value[1]
				}
				$('#b_testamentaryimmovable_ig_container_rm').show()
	    	}
	    	else if (a == 11.2) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.c_testamentaryimmovable_ig_index) + 1
					vm.c_testamentaryimmovable_ig_index++
					container = 'c_testamentaryimmovable_ig_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'c_testamentaryimmovable_ig_container'+value[0]; //div id container
					vm.c_testamentaryimmovable_ig_index = index = value[1]
				}
				$('#c_testamentaryimmovable_ig_container_rm').show()
	    	}
	    	else if (a == 12) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.testamentaryimmovable_sg_index) + 1
					vm.testamentaryimmovable_sg_index++
					container = 'testamentaryimmovable_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'testamentaryimmovable_sg_container'+value[0]; //div id container
					vm.testamentaryimmovable_sg_index = index = value[1]
				}
				$('#testamentaryimmovable_sg_container_rm').show()
	    	}
	    	else if (a == 120) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.testamentaryimmovable_ssg_index) + 1
					vm.testamentaryimmovable_ssg_index++
					container = 'testamentaryimmovable_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'testamentaryimmovable_ssg_container'+value[0]; //div id container
					vm.testamentaryimmovable_ssg_index = index = value[1]
				}
				$('#testamentaryimmovable_ssg_container_rm').show()
	    	}
	    	else if (a == 12.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.b_testamentaryimmovable_sg_index) + 1
					vm.b_testamentaryimmovable_sg_index++
					container = 'b_testamentaryimmovable_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'b_testamentaryimmovable_sg_container'+value[0]; //div id container
					vm.b_testamentaryimmovable_sg_index = index = value[1]
				}
				$('#b_testamentaryimmovable_sg_container_rm').show()
	    	}
	    	else if (a == 120.1) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.b_testamentaryimmovable_ssg_index) + 1
					vm.b_testamentaryimmovable_ssg_index++
					container = 'b_testamentaryimmovable_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'b_testamentaryimmovable_ssg_container'+value[0]; //div id container
					vm.b_testamentaryimmovable_ssg_index = index = value[1]
				}
				$('#b_testamentaryimmovable_ssg_container_rm').show()
	    	}
	    	else if (a == 12.2) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.c_testamentaryimmovable_sg_index) + 1
					vm.c_testamentaryimmovable_sg_index++
					container = 'c_testamentaryimmovable_sg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'c_testamentaryimmovable_sg_container'+value[0]; //div id container
					vm.c_testamentaryimmovable_sg_index = index = value[1]
				}
				$('#c_testamentaryimmovable_sg_container_rm').show()
	    	}

	    	else if (a == 120.2) {
	    		if (ctr_val=='') { //if blank index++
					index = parseInt(vm.c_testamentaryimmovable_ssg_index) + 1
					vm.c_testamentaryimmovable_ssg_index++
					container = 'c_testamentaryimmovable_ssg_container'+ci1; //div id container
				}else{ //else get the index from the db
					var value = ctr_val.split('_');
					container = 'c_testamentaryimmovable_ssg_container'+value[0]; //div id container
					vm.c_testamentaryimmovable_ssg_index = index = value[1]
				}
				$('#c_testamentaryimmovable_ssg_container_rm').show()
	    	}
			// hidden field for count
			// var a = $("<input type='hidden'/>")
			// .attr('name', x+index)
			// .attr('id', x+index)
			// .attr('value', container)
			// .appendTo("#"+container);
			// console.log(container)
			var index_value = a
			//for section 789. Oct 31 email
			var option_arrays = [1, 2, 2.1, 3, 4, 4.1, 5, 6, 6.1];
			var option_arrays2 = [7, 8, 81, 9, 10,100, 7.1, 8.1, 81.1, 9.1, 10.1, 100.1, 7.2, 8.2, 81.2, 9.2, 10.2, 100.2, 11, 12, 120, 11.1, 12.1, 120.1, 11.2, 12.2, 120.2];
			var a = $("<input type='hidden'/>")
			.attr('name', ci2+index)
			.attr('id', ci2+index)
			.attr('value', ci1+'_'+index)
			.appendTo("#"+container);
			
			if(index==1)
			{
				var headerGroup1 = $("<div class='col-md-3'><h5>Beneficiary</h5></div>");
				var headerGroup3 = $("<div class='col-md-3'><h5>Substitute</h5></div>");
				var headerGroup2;
				if (index_value == 3 || index_value == 4 || index_value == 4.1) {
					headerGroup2 = $("<div class='col-md-5'><h5> Share% / Joint Tenancy </h5></div>");
				}else{
					headerGroup2 = $("<div class='col-md-5'><h5>Share%</h5></div>");
				}
				
				var headerGroup4 = $("<div class='col-md-1'></div>");

				var headerGroup = $("<div class='form-group'></div>");
				
				headerGroup1.appendTo(headerGroup);
				headerGroup2.appendTo(headerGroup);
				headerGroup3.appendTo(headerGroup);
				headerGroup4.appendTo(headerGroup);

				//formGroup.appendTo("#"+container);
				headerGroup.appendTo("#"+container);
			}

			var colGroup1 = $("<div class='col-md-3 text-right'></div>");
			var colGroup2 = $("<div class='col-md-5'></div>");
			var colGroup3 = $("<div class='col-md-3'></div>");
			var colGroup4 = $("<div class='col-md-1'></div>");
			var colGroup2_1 = $("<div class='col-md-3 text-right'><label class='control-label'>Remarks</label></div>");
			var colGroup2_2 = $("<div class='col-md-9'></div>");
			var colGroup3_1 = $("<div class='col-md-3 text-right'><label class='control-label'>Other Substitute</label></div>");
			var colGroup3_2 = $("<div class='col-md-8'></div>");

			

			// if (index_value==9 || index_value==9.1 || index_value==9.2 || index_value==10 || index_value==10.1 || index_value==10.2 || index_value==100 || index_value==100.1 || index_value==100.2 || index_value==11 || index_value==11.1 || index_value==11.2 || index_value==12 || index_value==12.1 || index_value==12.2 || index_value==120 || index_value==120.1 || index_value==120.2) {
			if (false) {
				var b = $("<input class='form-control' type='text'>")
					.attr('name', y+index)
					.attr('id', y+index)
					.appendTo(colGroup1);
			}else{
				var b = $("<select class='form-control beneficiary_select'></select>")
					.attr('name', y+index)
					.attr('id', y+index)
					.appendTo(colGroup1);
					// console.log(index_value)
			}
			
			$("<input type='text' class='form-control'>")
			.attr('name', z+index)
			.attr('id', z+index)
			.appendTo(colGroup2);
			
			var c = $("<select class='form-control beneficiary_code'></select>")
			.attr('name', w+index)
			.attr('id', w+index)
			.appendTo(colGroup3);

			//Delete button
			var removeButton = $("<button type='button' class='pull-right btn btn-danger'> <i class='fa fa-trash-o'></i> </button>")
			.appendTo(colGroup4);

			//remarks
			$("<textarea class='form-control'></textarea>")
			.attr('name', r+index)
			.attr('id', r+index)
			.appendTo(colGroup2_2);

			//other substitute
			$("<input type='text' class='form-control'>")
			.attr('name', r+index)
			.attr('id', r+index)
			.appendTo(colGroup3_2);

			var formGroup = $("<div class='form-group'></div>");
			var formGroup2 = $("<div class='form-group'></div>");
			var formGroup3 = $("<div style='display:none;' class='form-group'></div>");
			colGroup1.appendTo(formGroup);
			colGroup2.appendTo(formGroup);
			colGroup3.appendTo(formGroup);
			colGroup4.appendTo(formGroup);
			colGroup2_1.appendTo(formGroup2);
			colGroup2_2.appendTo(formGroup2);
			colGroup3_1.appendTo(formGroup3);
			colGroup3_2.appendTo(formGroup3);

			removeButton.click(function() {

				// console.log(vm.mabeneficiary_ig)

		    	if(!confirm('Are you sure you want to remove this beneficiary?'))
		    	{
		    		return false;
		    	}
				
		    	
            	formGroup.remove();
            	formGroup2.remove();
            	formGroup3.remove();
            	//remove hidden
            	a.remove();
            	

            	var selCode = $("[id^="+z+"]");
            	var len = selCode.length;

            	var sel = $("[id^="+w+"]");

            	// $("[id^="+w+"] option").remove();
            	console.log(len)
            	if(len==1)
            	{

     //        		$("<option value='CHL'>CHL</option>").appendTo(sel);
					// $("<option value='OTP'>OTP</option>").appendTo(sel);
					
					// if (option_arrays.indexOf(index_value) > -1) {
					// 	$("<option value='RES'>RES</option>").appendTo(sel);
					// 	$("<option value='EST'>EST</option>").appendTo(sel);
					// }

					// if (option_arrays2.indexOf(index_value) > -1) {
					// 	$("<option value='EST'>EST</option>").appendTo(sel);
					// }
					// $("<option value='NA'>N/A</option>").appendTo(sel);

					// selCode.first().val("100%");

		    		setTimeout(function(){ 
		    				var sel1 = sel.eq(0).val() //get the first value
		    				// var sel2 = sel.eq(1).val() //get the first value
		    				$("[id^="+w+"] option").remove(); //remove all select
		    				//append the option value
		    				
			    			$("<option value='CHL'>CHL</option>").appendTo(sel);
							$("<option value='OTP'>OTP</option>").appendTo(sel);
							if (option_arrays.indexOf(index_value) > -1) {
								$("<option value='RES'>RES</option>").appendTo(sel);
								$("<option value='EST'>EST</option>").appendTo(sel);
							}

							if (option_arrays2.indexOf(index_value) > -1) {
								$("<option value='EST'>EST</option>").appendTo(sel);
							}
							$("<option value='NA'>N/A</option>").appendTo(sel);
							//set selected
		    				sel.eq(0).val(sel1)
		    				// sel.eq(1).val(sel2)
		    			}, 100);
            	
            	}

            	else if (len==2)
            	{
            		setTimeout(function(){ 
	    				var sel1 = sel.eq(0).val() //get the first value
	    				var sel2 = sel.eq(1).val() //get the first value
	    				$("[id^="+w+"] option").remove(); //remove all select
	    				//append the option value
	    				$("<option value='SUR'>SUR</option>").appendTo(sel);
		    			$("<option value='CHL'>CHL</option>").appendTo(sel);
						$("<option value='OTP'>OTP</option>").appendTo(sel);
						if (option_arrays.indexOf(index_value) > -1) {
							$("<option value='RES'>RES</option>").appendTo(sel);
							$("<option value='EST'>EST</option>").appendTo(sel);
						}

						if (option_arrays2.indexOf(index_value) > -1) {
							$("<option value='EST'>EST</option>").appendTo(sel);
						}
						$("<option value='NA'>N/A</option>").appendTo(sel);
						//set selected
	    				sel.eq(0).val(sel1)
	    				sel.eq(1).val(sel2)
	    			}, 100);
	    		}
            	
            	else 
            	{
            		setTimeout(function(){ 
	    				var sel_var = [] //initialize array
	    				//assign select value to array
	    				for (var i = 0; i <= selLength; i++) {
	    					sel_var[i] = sel.eq(i).val()
	    				};
	    				
	    				$("[id^="+w+"] option").remove(); //remove all select
						//append the option value
	    				$("<option value='SUR'>SUR</option>").appendTo(sel);
		    			$("<option value='PRO'>PRO</option>").appendTo(sel);
						$("<option value='EQU'>EQU</option>").appendTo(sel);
		    			$("<option value='CHL'>CHL</option>").appendTo(sel);
						$("<option value='OTP'>OTP</option>").appendTo(sel);
					

						if (option_arrays.indexOf(index_value) > -1) {
							$("<option value='RES'>RES</option>").appendTo(sel);
							$("<option value='EST'>EST</option>").appendTo(sel);
						}

						if (option_arrays2.indexOf(index_value) > -1) {
							$("<option value='EST'>EST</option>").appendTo(sel);
						}


						$("<option value='NA'>N/A</option>").appendTo(sel);
						//set selected
						for (var i = 0; i <= selLength; i++) {
	    					sel.eq(i).val(sel_var[i])
	    				};
	    			}, 100);
            	}
       		});

			formGroup.appendTo("#"+container);
			formGroup3.appendTo("#"+container);
			// formGroup2.appendTo("#"+container);

			
		
			

			var len = $("[id^="+z+"]").length;
			// console.log(len)
			if(len == 1) 
	    	{	
	    		//alert("#"+z+index);
	    		$("#"+z+index).val("100%");
	    		// $("#"+z+index).attr("disabled", "disabled");
	    		// $("#"+z+index).attr("readonly", "readonly");

	    		$("<option value='CHL'>CHL</option>").appendTo(c);
				$("<option value='OTP'>OTP</option>").appendTo(c);
				if (option_arrays.indexOf(index_value) > -1) {
					$("<option value='RES'>RES</option>").appendTo(c);
					$("<option value='EST'>EST</option>").appendTo(c);
				}
				if (option_arrays2.indexOf(index_value) > -1) {
					$("<option value='EST'>EST</option>").appendTo(c);
				}
				$("<option value='NA'>N/A</option>").appendTo(c);

	    	}

	    	else
	    	{	
	    		//Remove the options
	    		// $("[id^="+w+"] option").remove();
	    		// $("[id^="+w+"] option")
    		
	    		var sel = $("[id^="+w+"]");
	    		// console.log(sel)
	
	    		var selLength = sel.length
	    		
	    		if(len == 2 )
	    		{
	    			setTimeout(function(){ 
	    				var sel1 = sel.eq(0).val() //get the first value
	    				$("[id^="+w+"] option").remove(); //remove all select
	    				//append the option value
	    				$("<option value='SUR'>SUR</option>").appendTo(sel);
		    			$("<option value='CHL'>CHL</option>").appendTo(sel);
						$("<option value='OTP'>OTP</option>").appendTo(sel);
						
						if (option_arrays.indexOf(index_value) > -1) {
							$("<option value='RES'>RES</option>").appendTo(sel);
							$("<option value='EST'>EST</option>").appendTo(sel);
						}
						if (option_arrays2.indexOf(index_value) > -1) {
							$("<option value='EST'>EST</option>").appendTo(sel);
						}
						$("<option value='NA'>N/A</option>").appendTo(sel);
						//set selected
	    				sel.eq(0).val(sel1)
	    			}, 100);
    			}

	    		else 
	    		{
	    			setTimeout(function(){ 
	    				var sel_var = [] //initialize array
	    				//assign select value to array
	    				for (var i = 0; i <= selLength; i++) {
	    					sel_var[i] = sel.eq(i).val()
	    				};
	    				
	    				$("[id^="+w+"] option").remove(); //remove all select
						//append the option value
	    				$("<option value='SUR'>SUR</option>").appendTo(sel);
		    			$("<option value='PRO'>PRO</option>").appendTo(sel);
						$("<option value='EQU'>EQU</option>").appendTo(sel);
		    			$("<option value='CHL'>CHL</option>").appendTo(sel);
						$("<option value='OTP'>OTP</option>").appendTo(sel);
						

						if (option_arrays.indexOf(index_value) > -1) {
							$("<option value='RES'>RES</option>").appendTo(sel);
							$("<option value='EST'>EST</option>").appendTo(sel);
						}
						if (option_arrays2.indexOf(index_value) > -1) {
							$("<option value='EST'>EST</option>").appendTo(sel);
						}

						$("<option value='NA'>N/A</option>").appendTo(sel);
						//set selected
						for (var i = 0; i <= selLength; i++) {
	    					sel.eq(i).val(sel_var[i])
	    				};
	    			}, 100);

	    			
	    		}
	    		
	    		// $("[id^="+z+"]").removeAttr("disabled");
	    		$("[id^="+z+"]").removeAttr("readonly");

	    	}
			
			var beneficiary_select = $('#'+container+' .beneficiary_select') //all beneficiries select inside container
	    	var ben_var = [] //initialize array | temp val of selected beneficiaries 
	    	for (var i = 0; i < beneficiary_select.length-1; i++) { //store selected val in ben_var array
				ben_var[i] = beneficiary_select.eq(i).val()
			};

	    	$('#'+container+' .beneficiary_select option').remove(); //remove all benefeciaries option inside container after storing to ben_var array

			var ben_arr = [] //initialize array | array to store all guardian, executor, beneficiary
			$("[id^='guardian_name'], [id^='executor_name'], [id^='beneficiary_name']").each(function(){
    			ben_arr.push($(this).val()) //push guardian, executor, beneficiary to ben_arr array
			});

			beneficiary_select.each(function(){  //loop thru each beneficiary_select
				var this_beneficiary_select = $(this)
				
				$.each( ben_arr, function( i, val ) { //append ben_arr to beneficiary_select option
					var b = $("<option>"+val+"</option>")
	    			.attr('value',val);
					this_beneficiary_select.append(b)
				});
			})
			
			for (var i = 0; i < beneficiary_select.length-1; i++) {
				beneficiary_select.eq(i).val(ben_var[i]) //restore selection after remove
			};

			$('.beneficiary_code').on('change', function (){
    			var other_subs = $(this).closest('.form-group').next()
    			if ($(this).val()=='OTP') {
    				other_subs.show()
    			}else{
    				other_subs.hide()
    			}
    		})

    		$timeout( function(){ 
	    		$('.beneficiary_code').each(function(){
	    			// console.log($(this).val(), ctr_val)
	    			var other_subs = $(this).closest('.form-group').next()
	    			if ($(this).val()=='OTP') {
	    				other_subs.show()
	    			}
	    		})
    		}, 1000)

    		// console.log('retrieveselect')
    		// vm.retrieveSelect()
	    }

	   function removeMabeneficiary_ig (e) {
	    	var item = vm.mabeneficiary_ig.indexOf(e);
	    	vm.mabeneficiary_ig.splice(item,1);
	    }

	    function removeDabeneficiary_ig (e) {
	    	var item = vm.dabeneficiary_ig.indexOf(e);
	    	vm.dabeneficiary_ig.splice(item,1);
	    }

	    function addIabeneficiary_ig () {
	    	
	    	vm.iabeneficiary_ig.push({
	    		'iabeneficiary_ig_id':vm.iabeneficiary_ig.length+1
	    	});
	    }

	   function removeIabeneficiary_ig (e) {
	    	var item = vm.iabeneficiary_ig.indexOf(e);
	    	vm.iabeneficiary_ig.splice(item,1);
	    }

	    function addResiduary_ig () {
	    	
	    	vm.residuary_ig.push({
	    		'residuary_ig_id':vm.residuary_ig.length+1
	    	});
	    }

	   function removeResiduary_ig (e) {
	    	var item = vm.residuary_ig.indexOf(e);
	    	vm.residuary_ig.splice(item,1);
	    }

	    function addResiduary_sg () {
	    	vm.residuary_sg.push({
	    		'residuary_sg_id':vm.residuary_sg.length+1
	    	});
	    }

	   function removeResiduary_sg (e) {
	    	var item = vm.residuary_sg.indexOf(e);
	    	vm.residuary_sg.splice(item,1);
	    }


	    function addMabeneficiary_sg () {
	    	vm.mabeneficiary_sg.push({
	    		'mabeneficiary_sg_id':vm.mabeneficiary_sg.length+1
	    	});
	    }

	   function removeMabeneficiary_sg (e) {
	    	var item = vm.mabeneficiary_sg.indexOf(e);
	    	vm.mabeneficiary_sg.splice(item,1);
	    }

	    function addDabeneficiary_sg () {
	    	vm.dabeneficiary_sg.push({
	    		'dabeneficiary_sg_id':vm.dabeneficiary_sg.length+1
	    	});
	    }

	   function removeDabeneficiary_sg (e) {
	    	var item = vm.dabeneficiary_sg.indexOf(e);
	    	vm.dabeneficiary_sg.splice(item,1);
	    }

	    function addIabeneficiary_sg () {
	    	vm.iabeneficiary_sg.push({
	    		'iabeneficiary_sg_id':vm.iabeneficiary_sg.length+1
	    	});
	    }

	   function removeIabeneficiary_sg (e) {
	    	var item = vm.iabeneficiary_sg.indexOf(e);
	    	vm.iabeneficiary_sg.splice(item,1);
	    }

	   	function removeBeneficiary (e) {
	    	
	    	if(!confirm('Are you sure you want to remove this beneficiary?'))
	    	{
	    		e.preventDefault();	
	    	}

	    	var item = vm.beneficiaries.indexOf(e);

	    	vm.beneficiaries.splice(item,1);
	    }


	   	function setAssetLocation (e) {
	    	vm.asset_location = e;
	    }

	    function setJointlyOwned (e) {
	    	vm.jointlyOwned = e;
	    }

	    function setDistribution (e) {
	    	vm.distribution = e;
	    }

	    function setPaymentExpenses (e) {
	    	vm.paymentExpense = e;
	    }

	    function setLanguage (e) {
	    	vm.language = e;
	    }

	    function setSpecialCondition (e) {
	    	vm.special_condition = e;
	    }

       	function setOralTranslator (e) {

    		vm.assets_theres_translator_options = e;

	    }
	    function setTestmentaryMovableAssets (e) {

    		vm.testamentarymovable_theres_movableassets = e;

	    }
	    
	    function setTestmentaryMovableAssets_b (e) {

    		vm.b_testamentarymovable_theres_movableassets = e;

	    }
	    
	    function setTestmentaryMovableAssets_c (e) {

    		vm.c_testamentarymovable_theres_movableassets = e;

	    }

	    function setTestmentaryImmovableAssets (e) {

    		vm.testamentaryimmovable_theres_immovableassets = e;

	    }

	    function setTestmentaryImmovableAssets_b (e) {

    		vm.b_testamentaryimmovable_theres_immovableassets = e;

	    }

	     function setTestmentaryImmovableAssets_c (e) {

    		vm.c_testamentaryimmovable_theres_immovableassets = e;

	    }


	    function setAppointedTrustee (e) {
    		vm.executor_appoint_trustee = e;
	    }

	    function setGuardian (e) {
    		vm.guardian_any = e;
	    }
	
	    function setGuardian2 (e) {
    		vm.guardian_any2 = e;
	    }

	    function setMovableAssets (e) {
    		vm.movableasset_any = e;
	     	$('.submit_button').trigger('click')
	    }

	     function setDigitalAssets (e) {
    		vm.digitalasset_any = e;
	     	$('.submit_button').trigger('click')
	    }

	     function setImmovableAssets (e) {
    		vm.immovableasset_any = e;
	     	$('.submit_button').trigger('click')
	    }

	    function setMovableAssetType (e) {
	    	vm.movable_asset_type = e;
	    }

	    function setDigitalAssetType (e) {
	    	vm.digital_asset_type = e;
	    }

	    function movableassetJointlyForm (n, e) {
	    	vm[e] = n;
	    }

	    function digitalassetJointlyForm (n, e) {
	    	vm[e] = n;
	    }

	    function load_radio_id_type(){
			$timeout( function(){ 
				$('.radio_id_type').on('change', function (){
					var this_radio = $(this)
					if (this_radio.val()==3) {
						this_radio.closest('.form-group').next().show()
						this_radio.closest('.form-group').next().find('.other_id_type').val('')
						this_radio.closest('.form-group').next().find('.radio_other_id_type').show()

					}else{
						this_radio.closest('.form-group').next().hide()
					}
				})
            }, 500);
        }
        function load_beneficiaries(){
        	$timeout( function(){ 
	    		$('.beneficiary_type').on('change', function (){
    				// console.log($(this).val())
    				if($(this).val() == '0') {
    					$(this).closest('.ng-scope').find('.beneficiary_gender, .beneficiary_relationship, .beneficiary_idtype').show()
    					// console.log('person')
    				}
    				else {
    					$(this).closest('.ng-scope').find('.beneficiary_gender, .beneficiary_relationship, .beneficiary_idtype').hide()

    					$(this).closest('.ng-scope').find('.beneficiary_gender_options1').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_gender_options2').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_id_type1').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_id_type2').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_id_type3').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_id_type4').prop("checked", false);
    					$(this).closest('.ng-scope').find('.beneficiary_relationship_input').val('')
						
						$(this).closest('.ng-scope').find('.beneficiary_other_id_type').show()    					
    					$(this).closest('.ng-scope').find('.beneficiary_other_id_input').show()
    					// $(this).closest('.ng-scope').find('.beneficiary_other_id_input').show()
    					// console.log('charity company')
    				}
	    		});
    			load_radio_id_type()
    		}, 0);
    	}

        function load_select_type(){
			$timeout( function(){ 
				$('.select_type').on('change', function (){
					var this_select = $(this)
					// console.log(this_select.find('option:selected').val())
					if (this_select.find('option:selected').val() == 'Others' || this_select.find('option:selected').val() == 'others') {
						this_select.closest('.form-group').next().show()
						this_select.closest('.form-group').next().find('.select_others').show()

					}else{
						this_select.closest('.form-group').next().hide()
					}
				})
            }, 500);
        }

   	    function addNewMovableAsset () {
	    	console.log('test')
	    	var index = vm.movableassets.length+1;
	    	
	    	vm.movableassets.push({
	    		'movableasset_id':index
	    	});

	    	$timeout( function(){ 

    			$("#movableasset_other_asset"+index).hide();

    			$("#movableasset_asset_type"+index).on('change', function (){
    				
    				if($("#movableasset_asset_type"+index).val() == '4') {
    					$("#movableasset_other_asset"+index).show();
    				}

    				else {
    					$("#movableasset_other_asset"+index).hide();
    				}
    			});

    			$("#movableasset_if_jointly_owned_options_form"+index).hide();

    			$("#movableasset_jointly_owned_options"+index+"_1").click(function (){
    				$("#movableasset_if_jointly_owned_options_form"+index).show();
    			});

    			$("#movableasset_jointly_owned_options"+index+"_2").click(function (){
    				$("#movableasset_if_jointly_owned_options_form"+index).hide();
    			});
            }, 0);

	    }

	    function addNewDigitalAsset () {
	    	// console.log('addNewDigitalAsset')
	    	var index = vm.digitalassets.length+1;
	    	
	    	vm.digitalassets.push({
	    		'digitalasset_id':index
	    	});

	    	// console.log(digitalassets)

	    	$timeout( function(){ 

    			$("#digitalasset_other_asset"+index).hide();

    			$("#digitalasset_asset_type"+index).on('change', function (){
    				
    				if($("#digitalasset_asset_type"+index).val() == '4') {
    					$("#digitalasset_other_asset"+index).show();
    				}

    				else {
    					$("#digitalasset_other_asset"+index).hide();
    				}
    			});

    			$("#digitalasset_if_jointly_owned_options_form"+index).hide();

    			$("#digitalasset_jointly_owned_options"+index+"_1").click(function (){
    				$("#digitalasset_if_jointly_owned_options_form"+index).show();
    			});

    			$("#digitalasset_jointly_owned_options"+index+"_2").click(function (){
    				$("#digitalasset_if_jointly_owned_options_form"+index).hide();
    			});
            }, 0);
	    }

	    function removeMovableAsset (e) {

	    	if(!confirm('Are you sure you want to remove this asset?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.movableassets.indexOf(e);
	    	vm.movableassets.splice(item,1);
	    }

	    function removeDigitalAsset (e) {

	    	if(!confirm('Are you sure you want to remove this digital asset?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.digitalassets.indexOf(e);
	    	vm.digitalassets.splice(item,1);
	    }

	    function setOwnership (e) {
	    	vm.ownership = e;
    	}

    	function setMortgageSettlement (e) {
    		vm.mortgageSettlement = e;
    	}

    	function addNewImmovableAsset () {

    		var index = vm.immovableAssets.length+1;

	    	vm.immovableAssets.push({'immovableasset_id': index});

	    	$timeout( function(){ 
    			
    			$("#immovableasset_other_mortgage_remains"+index).hide();

    			$("#immovableasset_mortgage_remains_options"+index).on('change', function (){

    				if($("#immovableasset_mortgage_remains_options"+index).val() == '2')
				    {
				    	$("#immovableasset_other_mortgage_remains"+index).show();
				    }
			    
				    else
				    {
				    	$("#immovableasset_other_mortgage_remains"+index).hide();
				    }
    			});
    		},0);
	    }

	    function removeImmovableAsset (e) {

	    	if(!confirm('Are you sure you want to remove this asset?'))
	    	{
	    		e.preventDefault();	
	    	}
	    	var item = vm.immovableAssets.indexOf(e);
	    	vm.immovableAssets.splice(item,1);
	    }

	    function showOtherIdInput () {
	    	 
	    	//For newly created 
	    	//Refactor
	    	$timeout( function(){ 
    			
    			$(".guardian_other_id_type1").hide();
    			$(".others_other_person_to_marry_id_type").hide();
    			$(".others_other_id_type").hide();
				$(".guardian_other_id_type").hide();
    			
    			$("#immovableasset_other_mortgage_remains1").hide();
    			$("#immovableasset_mortgage_remains_options1").on('change', function (){
    				if($("#immovableasset_mortgage_remains_options1").val() == '2')
				    {
				    	$("#immovableasset_other_mortgage_remains1").show();
				    }
				    else
				    {
				    	$("#immovableasset_other_mortgage_remains1").hide();
				    }
    			});

    			// $("#movableasset_other_asset1").hide();

    			// $("#movableasset_asset_type1").on('change', function (){
    				
    			// 	if($("#movableasset_asset_type1").val() == '4') {
    			// 		$("#movableasset_other_asset1").show();
    			// 	}
    			// 	else {
    			// 		$("#movableasset_other_asset1").hide();
    			// 	}
    			// });

    			
    			$(".movableasset_asset_type").on('change', function (){
    				if($(this).val() == '4') {
    					// alert('show')
    					$(this).closest('.form-group').next().show()
    				}
    				else {
    					// alert('hide')
    					$(this).closest('.form-group').next().hide()
    				}
    			});

    			$(".digitalasset_asset_type").on('change', function (){
    				if($(this).val() == '4') {
    					// alert('show')
    					$(this).closest('.form-group').next().show()
    				}
    				else {
    					// alert('hide')
    					$(this).closest('.form-group').next().hide()
    				}
    			});

    			

    			// $("#testamentarymovable_distribution_purpose_others").hide();

    			$('#testamentarymovable_distribution_purpose5').click(function() {
					$(this).is(':checked') ? $("#testamentarymovable_distribution_purpose_others").show('blind') : $("#testamentarymovable_distribution_purpose_others").hide('blind');
				});

				// $("#b_testamentarymovable_distribution_purpose_others").hide();

    			$('#b_testamentarymovable_distribution_purpose5').click(function() {
					$(this).is(':checked') ? $("#b_testamentarymovable_distribution_purpose_others").show('blind') : $("#b_testamentarymovable_distribution_purpose_others").hide('blind');
				});

				// $("#c_testamentarymovable_distribution_purpose_others").hide();

    			$('#c_testamentarymovable_distribution_purpose5').click(function() {
					$(this).is(':checked') ? $("#c_testamentarymovable_distribution_purpose_others").show('blind') : $("#c_testamentarymovable_distribution_purpose_others").hide('blind');
				});

				$("#movableasset_if_jointly_owned_options_form").hide();

    			$("#movableasset_jointly_owned_options_1").click(function (){
    				$("#movableasset_if_jointly_owned_options_form").show();
    			});

    			$("#movableasset_jointly_owned_options_2").click(function (){
    				$("#movableasset_if_jointly_owned_options_formload_radio_id_type").hide();
    			});

    			$("#digitalasset_if_jointly_owned_options_form").hide();

    			$("#digitalasset_jointly_owned_options_1").click(function (){
    				$("#digitalasset_if_jointly_owned_options_form").show();
    			});

    			$("#digitalasset_jointly_owned_options_2").click(function (){
    				$("#digitalasset_if_jointly_owned_options_formload_radio_id_type").hide();
    			});

            }, 0);

			//radio for all id_type
			load_beneficiaries()
			beneficiary_preload()
			//load select
			load_select_type()
			$("#movableasset_beneficiary_ig3").val();
			$("#digitalasset_beneficiary_ig3").val();
	    }

	    $('#addComment').click(function(){
			$('#commentsmodal').modal('show');
		})
	
	}

	



})();

