(function () {
	
	'use strict';
	
	angular
		.module('rockwills')
		.controller('templateCtrl', TemplateCtrl);

	function TemplateCtrl () {

		/* jshint validthis: true */
		var vm = this;

		//Initial Selection
	    vm.selected = null;

	    vm.setSelected = setSelected;
	    vm.isSelected = isSelected;

	    vm.field_names = [
	        {"title": "Will Status", "editor_name" : "status"},
	        {"title": "Name", "editor_name" : "testator_name"},
	        {"title": "Country", "editor_name" : "country", },
	        {"title": "ID Type", "editor_name" : "id_type"},
	        {"title": "Permanent Address", "editor_name" : "testator_address" },
	        {"title": "Correspondence Address", "editor_name" : "correspondence_address"},
	        {"title": "Birthdate", "editor_name" : "birthdate" },
	        {"title": "Religion", "editor_name" : "religion" },
	        {"title": "Contact Number", "editor_name" : "contact_number" },
	        {"title": "Email", "editor_name" : "email" },
	        {"title": "Marital Status", "editor_name" : "marital_status" },
	        {"title": "Nationality", "editor_name" : "nationality" },
	    ];

	    //Set the current selected
	    function setSelected (selected){
	      vm.selected = selected;
	    }

	    function isSelected (selected){
	      return vm.selected !== null && selected.title === vm.selected.title;
	    }

	}	

})(); 