(function() {
	'use strict';

	angular
		.module('rockwills')
		.controller('manageUserCtrl', ManageUserCtrl);

		function ManageUserCtrl ($http, $window) {

			var vm = this;
			vm.roles = null;
			vm.getRoles = getRoles;

			function getRoles () {
				$http.get('api/role/').
					success(function(data) {
							vm.roles = data;
					});
			}

			vm.getRoles();
		}

}());
