   (function () {
	'use strict';
	
	angular
		.module('rockwills')
		.controller('registerCtrl', RegisterCtrl);

	RegisterCtrl.$inject = ['$http'];

	function RegisterCtrl ($http) {
		var vm = this;
		vm.register = register;
		vm.sendRegistration = sendRegistration;
		vm.form =  new registerForm ();
		vm.addErrorMarker = addErrorMarker;

		function registerForm () {
			this.name = '';
			this.email = '';
			this.password = '';
			this.passwordConfirmation = '';
	        this.company = '';
			this.errors = [];
		};

		function register () {

		}

		function sendRegistration () {

			$http.post('/auth/register', vm.form)
            
            .success(function(user) {
                //$window.location = BASE_URL + '/dashboard';

            })
            .error(function(errors) {
            	console.log(errors);
                vm.form.errors = errors;
            });	
		}

		function addErrorMarker (e) {

			if(angular.isUndefined(e))
			{
				return 0;
			}

			return 1;
		}




	}
})(); 